import React, { useState, useEffect } from "react";
import "../assets/css/header.css";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Button from "../components/Button";
import { isValidPath } from "../utils/validation";
import Banner from "./Alert/Banner";
import { useLocation } from "react-router-dom";

export default function Header() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);
  const [showSolutionsDropdown, setShowSolutionsDropdown] = useState(false);
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    window.addEventListener("scroll", handleScroll);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsScrolled(scrollTop > 0);
  };

  const handleProductsDropdownHover = () => {
    setShowProductsDropdown(true);
    setShowSolutionsDropdown(false);
    setShowCompanyDropdown(false);
  };

  const handleSolutionsDropdownHover = () => {
    setShowSolutionsDropdown(true);
    setShowProductsDropdown(false);
    setShowCompanyDropdown(false);
  };

  const handleCompanyDropdownHover = () => {
    setShowCompanyDropdown(true);
    setShowProductsDropdown(false);
    setShowSolutionsDropdown(false);
  };

  const handleNavigation = (url) => {
    if (isValidPath(url)) {
      window.location.assign(url);
      window.location.reload();
    } else {
      console.warn("Invalid path:", url);
    }
  };

  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <header
      className={`Header ${isScrolled ? "scrolled" : ""} ${
        isHomePage ? "" : ""
        //home-header
      }`}
    >
      {/* {isHomePage && <Banner />} */}
      <a href="/" onClick={() => handleNavigation("/")} to="/">
        {" "}
        <img
          src={require("../assets/logos/logo.png")}
          className="Logo"
          alt="logo"
        />
      </a>
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav">
          <div
            className="NavItem"
            onMouseEnter={handleProductsDropdownHover}
            onMouseLeave={() => setShowProductsDropdown(false)}
          >
            <a href="/products" onClick={() => handleNavigation("/products")}>
              {" "}
              Products
            </a>{" "}
            <FontAwesomeIcon icon={faAngleDown} />
            <CSSTransition
              in={showProductsDropdown}
              timeout={350}
              classNames="DropdownAnimation"
              unmountOnExit
            >
              <div className="DropdownContent">
                <a
                  href="/cync-loan-origination-system-los"
                  onClick={() =>
                    handleNavigation("/cync-loan-origination-system-los")
                  }
                >
                  Cync LOS
                </a>
                <a
                  href="/cync-abl"
                  onClick={() => handleNavigation("/cync-abl")}
                >
                  Cync ABL
                </a>
                <a
                  href="/cync-syndicated-lending"
                  onClick={() => handleNavigation("/cync-syndicated-lending")}
                >
                  Cync Syndicated Lending
                </a>
                <a
                  href="/cync-spreading"
                  onClick={() => handleNavigation("/cync-spreading")}
                >
                  Cync Spreading
                </a>
                <a
                  href="/cync-factoring"
                  onClick={() => handleNavigation("/cync-factoring")}
                >
                  Cync Factoring
                </a>
                {/* <a onClick={() => handleNavigation('/cync-client')}>Cync Client Portal</a> */}
              </div>
            </CSSTransition>
          </div>

          <div
            className="NavItem"
            onMouseEnter={handleProductsDropdownHover}
            onMouseLeave={() => setShowProductsDropdown(false)}
          >
            Resources{" "}
             <FontAwesomeIcon icon={faAngleDown} /> 
            <CSSTransition
              in={showProductsDropdown}
              timeout={350}
              classNames="DropdownAnimation"
              unmountOnExit
            >
              <div className="DropdownContent">
                <a
                  href="/insights"
                  onClick={() => handleNavigation("/insights")}
                >
                  Insights
                </a>
                <a
                  href="/customer-stories"
                  onClick={() => handleNavigation("/customer-stories")}
                >
                  Customer Stories
                </a>

                {/* <a onClick={() => handleNavigation('/cync-client')}>Cync Client Portal</a> */}
              </div>
            </CSSTransition>
          </div>
          <div
            className="NavItem"
            onMouseEnter={handleCompanyDropdownHover}
            onMouseLeave={() => setShowCompanyDropdown(false)}
          >
            Company <FontAwesomeIcon icon={faAngleDown} />
            <CSSTransition
              in={showCompanyDropdown}
              timeout={350}
              classNames="DropdownAnimation"
              unmountOnExit
            >
              <div className="DropdownContent">
                <a href="/company" onClick={() => handleNavigation("/company")}>
                  About Us
                </a>
                <a
                  href="/cync-team"
                  onClick={() => handleNavigation("/cync-team")}
                >
                  Our Team
                </a>
                <a
                  href="/careers-culture"
                  onClick={() => handleNavigation("/careers-culture")}
                >
                  Careers & Culture
                </a>
                <a
                  href="/news-events"
                  onClick={() => handleNavigation("/news-events")}
                >
                  News & Events
                </a>
                <a
                  href="/security"
                  onClick={() => handleNavigation("/security")}
                >
                  Security
                </a>
              </div>
            </CSSTransition>
          </div>
          <a href="/contact" onClick={() => handleNavigation("/contact")}>
            Contact Us
          </a>
          <a
            href="/request-a-demo"
            onClick={() => handleNavigation("/request-a-demo")}
            style={{ margin: "auto" }}
          >
            <Button className="headerbtn" size="large" color="primarys">
              Schedule a Demo
            </Button>
          </a>
        </nav>
      </CSSTransition>
      <FontAwesomeIcon
        icon={faBars}
        onClick={() => setNavVisibility(!isNavVisible)}
        className="Burger"
      />
    </header>
  );
}
