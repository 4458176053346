import React, { useState, useEffect } from "react";
import "./Circleup.css";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import Button from "../../../Button.jsx";
import HrLines from "../../../designs/HrLines.jsx";
import Typography from "@mui/material/Typography";
import VideoModal from "../../../VideoModal.jsx";
import testimonials from "../../../../Data/testimonialsData.js";
import circleuptestimonial from "../../../../Data/CircleupData.js";
import { Helmet } from "react-helmet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import about from "./about.png";
import challenge from "./challenge.png";
import solution from "./solution.png";
import business from "./business.png";
import CircleupPopup from "../../../popup/LOS/CircleupPopup.jsx";
import { useNavigate } from "react-router-dom";

const Circleup = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const handleStepClick = (index) => {
    if (activeStep === index) {
      setActiveStep(null);
    } else {
      setActiveStep(index);
      setLastActiveStep(index);
    }
  };
  const [showPopup, setShowPopup] = useState(false);

 const navigate = useNavigate();

  const handleShow = () =>{
    // Leadfeeder tracking
    window.history.pushState({}, "", "/customer-stories/circleup/form");
    // Push event to Google Analytics / Leadfeeder (if using Google Tag Manager)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "popup_opened"
    });
  
    // If using Google Analytics (GA4)
    if (window.gtag) {
      window.gtag("event", "popup_opened", {
        event_category: "Popup",
        event_label: "Popup Form Opened",
      });
    }
  
    setShowPopup(true);
  };
  const handleClose = () => {
    navigate(-1);
    setShowPopup(false);
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };


  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  return (
    <div>
      <Helmet>
        <title>Customer Stories - Cync Software®</title>
        <meta
          name="description"
          content="Automate collateral evaluation and borrowing base calculations with our industry-leading asset-based lending platform. Schedule a demo!"
        />
      </Helmet>

      <section className="Parabilis-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h4
                  style={{
                    color: "#E93740",
                    fontSize: "50px",
                    fontWeight: "700",
                  }}
                >
                  Customer Story
                </h4>
                <h1 className="lostopheading">
                  How CircleUp Slashed Loan Servicing <br />
                  Costs and Increased Efficiency with <br />
                  Cync Asset-Based Lending
                </h1>
                <br />
                <span className="benefitshead">
                  <div className="benefitsgrids" style={{ width: "240px" }}>
                    <div className="icon">
                      <img
                        src={require("../../../../assets/icons/circle1.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{ height: "12px" }}></div>
                    <div className="text">
                      <h5 className="csheads">
                        Eliminated reliance on external vendor
                      </h5>
                    </div>
                  </div>
                  <div className="benefitsgrids" style={{ width: "210px" }}>
                    <div className="icon">
                      <img
                        src={require("../../../../assets/icons/circle2.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{ height: "12px" }}></div>
                    <div className="text">
                      <h5 className="csheads">
                        Real-time liability <br />
                        tracking
                      </h5>
                    </div>
                  </div>
                  <div className="benefitsgrids" style={{ width: "110px" }}>
                    <div className="icon">
                      <img
                        src={require("../../../../assets/icons/circle3.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{ height: "12px" }}></div>
                    <div className="text">
                      <h5 className="csheads">Increased capacity </h5>
                    </div>
                  </div>
                </span>
                <div style={{ height: "12px" }}></div>
                <br />
                <Button size="small" color="primary" onClick={handleShow}>
                  Achieve Similar Results
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="space"></div>

      <section className="container">
        <div className="sections">
          <div className="sections-content">
            <h1>About Our Client</h1>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            >
              <hr
                style={{
                  width: "75px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
              <hr
                style={{
                  width: "15px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
            </div>
          </div>
        </div>
        <div className="sections">
          <div className="sections-content">
            <p>
              Founded in 2012, CircleUp is a privately owned company with a
              mission to empower entrepreneurs by providing the funding and
              support needed for their success. With over 500 brands supported,
              CircleUp leverages its business technology platform, Helio, to
              make faster, more informed decisions in the private company
              landscape. Using a combination of data and machine learning,
              CircleUp helps underserved entrepreneurs access vital resources
              and transform the world of private enterprise.
            </p>
          </div>
          <div className="sections-image">
            <img src={about} alt="Placeholder" />
          </div>
        </div>
      </section>

      <div className="space"></div>

      <section className="bg">
        <section className="container">
          <div className="sections">
            <div className="sections-image"></div>
            <div className="sections-content">
              <h1>The Challenge</h1>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              >
                <hr
                  style={{
                    width: "75px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
                <hr
                  style={{
                    width: "15px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="sections">
            <div className="sections-image">
              <img src={challenge} alt="Placeholder" />
            </div>
            <div className="sections-content">
              <p>
                CircleUp faced inefficiencies in managing their loan servicing
                operations. The company relied on spreadsheets and external
                vendor, resulting in high costs and manual work. This approach
                hindered their ability to effectively analyze their portfolio
                and serve customers.
              </p>
              <ul>
                <li>Heavy reliance on spreadsheets for loan management.</li>
                <li>
                  Need for an external vendor to handle loan servicing,
                  resulting in increased costs.
                </li>
                <li>
                  Lack of real-time data and automated processes for better
                  portfolio analysis.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
      <div className="space"></div>

      <section className="container">
        <div className="sections">
          <div className="sections-contents">
            <h1>The Cync Solution</h1>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            >
              <hr
                style={{
                  width: "75px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
              <hr
                style={{
                  width: "15px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
            </div>
          </div>
        </div>
        <div className="sections">
          <div className="sections-content">
            <p>
              Cync Asset-Based Lending provided a customized, cloud-based
              solution that streamlined CircleUp’s loan operations. By switching
              from spreadsheets to a comprehensive online platform, CircleUp
              gained access to essential real-time data, enabling better loan
              management and improved decision-making.
            </p>
            <ul>
              <li>
                Transitioned from spreadsheets to a centralized online platform.
              </li>
              <li>
                Created a customized solution with loan summary and loan inquiry
                features.
              </li>
              <li>
                Gained real-time data on outstanding balances, fees, and
                interest.
              </li>
            </ul>
          </div>
          <div className="sections-image">
            <img src={solution} alt="Placeholder" />
          </div>
        </div>
        <br />
      </section>
      <div className="space"></div>

      <section className="bg">
        <section className="container">
          <div className="sections">
            <div className="sections-image"></div>
            <div className="sections-content">
              <h1>Business Impact</h1>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              >
                <hr
                  style={{
                    width: "75px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
                <hr
                  style={{
                    width: "15px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="sections">
            <div className="sections-image">
              <img src={business} alt="Placeholder" />
            </div>
            <div className="sections-content">
              <p>
                The implementation of Cync Software led to significant
                improvements in CircleUp’s loan servicing efficiency. With
                automated processes and real-time insights, the company was able
                to increase productivity and reduce costs, all while servicing
                more loans with the same team.
              </p>
              <ul>
                <li>
                  Eliminated reliance on external vendor, lowering operational
                  costs.
                </li>
                <li>
                  Automated manual tasks, improving accuracy and productivity.
                </li>
                <li>
                  Increased capacity to service more loans with the same amount
                  of staff.
                </li>
              </ul>
            </div>
          </div>
          <br />
        </section>
      </section>

      <section className="testimoni">
        <Carousel
          indicators={false}
          controls={false}
          interval={carouselPlaying ? 15 : null}
          fade={true} // Add fade effect for smooth transition
          className="carousel-container"
        >
          {circleuptestimonial.map((circleuptestimonial, index) => (
            <Carousel.Item key={index} className="carousel-item">
              <img
                className="d-block w-100 carousel-image"
                src={circleuptestimonial.image}
                alt={`Slide ${index + 1}`}
              />
              <Carousel.Caption className="carousel-caption-custom">
                <h1 className="headingwhite">{circleuptestimonial.heading}</h1>
                <p className="testimobpara">{circleuptestimonial.text}</p>
                <p className="author-text">{circleuptestimonial.author}</p>
                <p className="none"></p>
                <p
                  style={{ fontSize: "11.5px" }}
                  className="testimobpara dispara"
                >
                  <i>{circleuptestimonial.disclaimer}</i>
                </p>
                <div className="disclaimer">
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => handleOpen(circleuptestimonial.videoUrl)}
                  >
                    Watch Video
                  </Button>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <VideoModal open={open} handleClose={handleClose} videoUrl={videoUrl} />
      </section>

      <div className="space"></div>

      <section className="demo-section">
        <div className="demo-content">
          <h1>Supercharge Your Lending Now!</h1>
          <HrLines />
          <div className="space"></div>
          <Button size="small" color="primary" onClick={handleShow}>
            Schedule a Demo
          </Button>
          <CircleupPopup show={showPopup} handleClose={handleClose} />
        </div>
      </section>
    </div>
  );
};

export default Circleup;
