import React, { useState, useEffect } from "react";
import "../../assets/css/CustomerStories.css";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import Button from "../Button";
import HrLines from "../designs/HrLines";
import Typography from "@mui/material/Typography";
import VideoModal from "../VideoModal";
import { Helmet } from "react-helmet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import GeneralPopup from "../popup/LOS/GeneralPopup.jsx";
import TalkPopup from "../popup/LOS/TalkPopup.jsx";
import { useLocation } from "react-router-dom";
import { isValidPath } from "../../utils/validation.jsx";
import { useNavigate } from "react-router-dom";

const handleNavigation = (url) => {
  if (isValidPath(url)) {
    window.location.assign(url);
  } else {
    console.warn("Invalid path:", url);
  }
};


const CustomerStories = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const location = useLocation();
  useEffect(() => {
    // Check if the URL contains ?popup=true
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("popup") === "true") {
      setShowPopup(true); // Open popup automatically
    }
  }, [location]);

  const handleStepClick = (index) => {
    if (activeStep === index) {
      setActiveStep(null);
    } else {
      setActiveStep(index);
      setLastActiveStep(index);
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const [showAnotherPopup, setShowAnotherPopup] = useState(false);

const navigate = useNavigate();

  const handleShow = () =>{
    // Leadfeeder tracking
    window.history.pushState({}, "", "/customer-stories/form");
    // Push event to Google Analytics / Leadfeeder (if using Google Tag Manager)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "popup_opened"
    });
  
    // If using Google Analytics (GA4)
    if (window.gtag) {
      window.gtag("event", "popup_opened", {
        event_category: "Popup",
        event_label: "Popup Form Opened",
      });
    }
  
    setShowPopup(true);
  };
  const handleClose = () => {
    navigate(-1);
    setShowPopup(false);
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };

  const handleShowAnotherPopup = () =>{
    // Leadfeeder tracking
    window.history.pushState({}, "", "/customer-stories/form");
    // Push event to Google Analytics / Leadfeeder (if using Google Tag Manager)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "popup_opened"
    });
  
    // If using Google Analytics (GA4)
    if (window.gtag) {
      window.gtag("event", "popup_opened", {
        event_category: "Popup",
        event_label: "Popup Form Opened",
      });
    }
   setShowAnotherPopup(true);
  }
  const handleCloseAnotherPopup = () => {
    navigate(-1);
    setShowAnotherPopup(false);
  }


  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  return (
    <div>
      <Helmet>
        <title>Customer Stories - Cync Software®</title>
        <meta
          name="description"
          content="Automate collateral evaluation and borrowing base calculations with our industry-leading asset-based lending platform. Schedule a demo!"
        />
      </Helmet>

      <section className="cstop-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h4
                  style={{
                    color: "#E93740",
                    fontSize: "50px",
                    fontWeight: "700",
                  }}
                >
                 Customer Stories
                </h4>
                <h1 className="mobsize">
                Discover How Cync Powers<br/> Transformation
                </h1>
                <br />
                <div style={{height:'12px'}}></div>
                <Button size="small" color="primary" onClick={handleShowAnotherPopup}>
                Talk to Us!
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* umpbank */}

      <section className="Umpbanks-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={7}>
              <div className="content">
                <h1 className="mobsize">
                  How Umpqua Bank Slashed <br />
                  Borrowing Base Processing<br /> Time
                   by 92.5% With Cync ABL
                </h1>
                <br />
                <span className="benefitshead">
                  <div className="benefitsgrids" style={{ width: "160px" }}>
                    <div className="icon">
                      <img
                        src={require("../../assets/icons/2x.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div className="text">
                      <h6 className="cshead">2X</h6>
                      <h5 className="csheads">Increase in productivity</h5>
                    </div>
                  </div>
                  <div className="benefitsgrids" style={{ width: "200px" }}>
                    <div className="icon">
                      <img
                        src={require("../../assets/icons/3x.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div className="text">
                      <h6 className="cshead">3.3X</h6>
                      <h5 className="csheads">
                        Faster <br />
                        turnaround time
                      </h5>
                    </div>
                  </div>
                  <div className="benefitsgrids" style={{ width: "110px" }}>
                    <div className="icon">
                      <img
                        src={require("../../assets/icons/80.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div className="text">
                      <h6 className="cshead">60-80</h6>
                      <h5 className="csheads">Portfolios per analysts</h5>
                    </div>
                  </div>
                </span>
                <br />
                <p>Umpqua Bank faced significant challenges in managing a diverse portfolio<br/> of over 200 credits, ranging from $1 million to $35 million.... </p>
                <br />
                <div style={{height:'12px'}}></div>
                <a href="/customer-stories/umpqua-bank" onClick={() => handleNavigation("/customer-stories/umpqua-bank")}>
                <Button size="small" color="primary">
                Read More
                </Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

  

      {/* Parabilis */}

      <section className="Parabiliss-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
          <Col lg={6}>
          </Col>
            <Col lg={6}>
              <div className="content">
        
                <h1 className="mobsize">
                  How Parabilis Gained Granular 
                  Insights Into Borrower’s 
                  Receivables Using Cync ABL
                </h1>
                <br />
                <span className="benefitshead">
                  <div className="benefitsgrids" style={{ width: "200px" }}>
                    <div className="icon">
                      <img
                        src={require("../../assets/icons/para1.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{height:'12px'}}></div>
                    <div className="text">
                      <h5 className="csheads">
                        Granular Collateral Segregation
                      </h5>
                    </div>
                  </div>
                  <div className="benefitsgrids" style={{ width: "230px" }}>
                    <div className="icon">
                      <img
                        src={require("../../assets/icons/para2.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{height:'12px'}}></div>
                    <div className="text">
                      <h5 className="csheads">
                        Increased Transparency for Clients
                      </h5>
                    </div>
                  </div>
                  <div className="benefitsgrids" style={{ width: "110px" }}>
                    <div className="icon">
                      <img
                        src={require("../../assets/icons/para3.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{height:'12px'}}></div>
                    <div className="text">
                      <h5 className="csheads">On-Demand Scalability</h5>
                    </div>
                  </div>
                </span>
                <div style={{height:'12px'}}></div>
                <p>Parabilis, a financial services institution based out of McLean, Virginia, is dedicated to providing government contractors with better access to funds through a transparent...</p>
                <br />
                <a href="/customer-stories/parabilis" onClick={() => handleNavigation("/customer-stories/umpqua-bank")}>
                <Button size="small" color="primary">
                Read More
                </Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


                  {/* circleup */}
     

      <section className="circleup-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h1 className="mobsize">
                  How CircleUp Slashed Loan  <br /> Servicing
                  Costs and <br />Increased Efficiency with <br />
                  Cync Asset-Based Lending
                </h1>
                <br />
                <span className="benefitshead">
                  <div className="benefitsgrids" style={{ width: "240px" }}>
                    <div className="icon">
                      <img
                        src={require("../../assets/icons/circle1.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{ height: "12px" }}></div>
                    <div className="text">
                      <h5 className="csheads">
                        Eliminated reliance on external vendor
                      </h5>
                    </div>
                  </div>
                  <div className="benefitsgrids" style={{ width: "210px" }}>
                    <div className="icon">
                      <img
                        src={require("../../assets/icons/circle2.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{ height: "12px" }}></div>
                    <div className="text">
                      <h5 className="csheads">
                        Real-time liability <br />
                        tracking
                      </h5>
                    </div>
                  </div>
                  <div className="benefitsgrids" style={{ width: "110px" }}>
                    <div className="icon">
                      <img
                        src={require("../../assets/icons/circle3.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{ height: "12px" }}></div>
                    <div className="text">
                      <h5 className="csheads">Increased capacity </h5>
                    </div>
                  </div>
                </span>
                <div style={{ height: "12px" }}></div>
                <p>Founded in 2012, CircleUp is a privately owned company with a mission<br /> to empower entrepreneurs by providing the funding and support needed<br /> for their success...</p>
                <br />
                <a href="/customer-stories/circleup" onClick={() => handleNavigation("/customer-stories/umpqua-bank")}>
                <Button size="small" color="primary">
                Read More
                </Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

     

      <section className="demo-section">
        <div className="demo-content">
          <h1>Supercharge Your Lending Now!</h1>
          <HrLines />
          <div className="space"></div>
          <Button size="small" color="primary" onClick={handleShow}>
          Achieve Similar Results
          </Button>
          <GeneralPopup show={showPopup} handleClose={handleClose} />
          <TalkPopup show={showAnotherPopup} handleClose={handleCloseAnotherPopup} />
        </div>
      </section>
    </div>
  );
};

export default CustomerStories;
