import React, { useState, useEffect } from "react";
import "../../assets/css/CyncSyndicate.css";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import Button from "../Button";
import HrLines from "../designs/HrLines";
import Typography from "@mui/material/Typography";
import SyndicatedPopup from "../popup/LOS/SyndicatedPopup.jsx";
import VideoModal from "../VideoModal";
import testimonials from "../../Data/testimonialsData.js";
import { syndicateboxes } from "../../Data/boxesData.js";
import { syndicatesteps } from "../../Data/stepsData.js";
import reverse from "../../assets/icons/reverse.png";
import { Helmet } from "react-helmet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import SyndicatedStepBoxes from "../StepBoxes/SyndicatedStepBoxes.jsx";
import { useNavigate } from "react-router-dom";

const flipBox = (id) => {
  const clickedBox = document.getElementById(`box-${id}`);
  const allBoxes = document.querySelectorAll(".box");

  // Remove 'flipped' class from all boxes except the clicked one
  allBoxes.forEach((box) => {
    if (box !== clickedBox) {
      box.classList.remove("flipped");
    }
  });

  // Toggle 'flipped' class for the clicked box
  clickedBox.classList.toggle("flipped");
};

const CyncSpreading = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const handleStepClick = (index) => {
    if (activeStep === index) {
      setActiveStep(null);
    } else {
      setActiveStep(index);
      setLastActiveStep(index);
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();
 
   const handleShow = () =>{
     // Leadfeeder tracking
     window.history.pushState({}, "", "/cync-syndicated-lending/form");
     // Push event to Google Analytics / Leadfeeder (if using Google Tag Manager)
     window.dataLayer = window.dataLayer || [];
     window.dataLayer.push({
       event: "popup_opened"
     });
   
     // If using Google Analytics (GA4)
     if (window.gtag) {
       window.gtag("event", "popup_opened", {
         event_category: "Popup",
         event_label: "Popup Form Opened",
       });
     }
   
     setShowPopup(true);
   };
   const handleClose = () => {
     navigate(-1);
     setShowPopup(false);
     setOpen(false);
     setVideoUrl("");
     setCarouselPlaying(true);
   };
 

  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  const imageContainerStyle = {
    minHeight: "", // Set a minimum height for the image container
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px", // Adjust as needed
    overflow: "hidden", // Hide overflow to prevent abrupt resizing
    transition: "height 0.3s ease-in-out", // Smooth transition for height changes
    height: activeStep !== null ? "420px" : "300px", // Dynamic height based on activeStep
  };

  return (
    <div>
      <Helmet>
        <title>
          Syndicated Loans | Participation Loans - Cync Syndicated Lending -
          Cync Software®
        </title>
        <meta
          name="description"
          content="Confidently manage complex syndicated or participation loans with our flexible, highly-automated lending platform. Schedule a demo today!"
        />
      </Helmet>

      <section className="syndicatetop-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h4
                  style={{
                    color: "#E93740",
                    fontSize: "50px",
                    fontWeight: "700",
                  }}
                >
                  Cync Syndicated Lending
                </h4>
                <h1 className="lostopheading">
                  Highly Flexible, Automated Solution for Simplifying Syndicated
                  Loan Management
                </h1>
                <p className="toppara">
                  Automate the servicing of both syndicated and participation
                  loans. Seamlessly handle <br />
                  complexities like dividing loans into multiple tranches,
                  prioritizing lender allocations,
                  <br /> and triggering drawdowns as needed. Allow syndicate
                  participants and borrowers
                  <br /> exceptional flexibility to structure contracts for
                  their unique financing and risk
                  <br />
                  management requirements.
                </p>
                <Button size="small" color="primary" onClick={handleShow}>
                  Product Demo
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="space"></div>

      <section>
        <Container className="h-100">
          <h1 className="privacyheading">
            Supercharge Your Lending With Cync Syndicated Lending
          </h1>
          <HrLines />
          <br />
          <p className="contentpara">
            With the number of parties involved, each with their own interests
            and requirements, syndicated lending agreements are inherently
            complex. Detailed terms, including varying tranches with distinct
            interest rates and maturities, intricate payment and fee
            allocations, and stringent covenants, lie buried within these
            agreements, which can easily extend over a hundred pages. Managing
            amendments, triggering drawdowns, and restructuring participants
            further adds to the complexity. Additionally, coordinating between
            lead arrangers, agents, participants, and borrowers while ensuring
            precise documentation and communication exacerbates the challenge.
            As lead arrangers and agents seek to expand their syndication
            portfolios to diversify risks, improve client relationships, or gain
            access to larger deals, outdated software, and cumbersome
            spreadsheets limit their ability to respond swiftly to market
            demands.
          </p>
          <p className="contentpara">
            Say hello to Cync Syndicated Lending—a highly configurable,
            cloud-native solution designed to manage every facet of your complex
            syndicated lending contracts with ease. Eliminate the spreadsheets
            and embrace automation to streamline your lending operations,
            empowering your financial institution to focus on what truly
            matters—growing your business.
          </p>
        </Container>
      </section>

      <div className="space"></div>

      <section className="benefits-with-grids">
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/development.png")}
              alt="Icon 1"
            />
          </div>
          <div className="text">
            <h6>100%</h6>
            <h5>
              Customizable to Support Every Syndicated Lending Arrangement
            </h5>
          </div>
        </div>
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/timesaving.png")}
              alt="Icon 2"
            />
          </div>
          <div className="text">
            <h6>75%</h6>
            <h5>
              Reduction in Time for Asset Monitoring and Participant & Loan
              Management
            </h5>
          </div>
        </div>
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/portfoliowhite.png")}
              alt="Icon 2"
            />
          </div>
          <div className="text">
            <h6>2X</h6>
            <h5>Portfolios Handled per Credit Analyst</h5>
          </div>
        </div>
      </section>
      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Why Cync Syndicated Lending?</h1>
          <HrLines />
          <div className="space"></div>
          <SyndicatedStepBoxes />
        </Container>
      </section>

      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Expansive Feature Set</h1>
          <HrLines />
          <div className="space"></div>
          <Row className="box-row">
            {syndicateboxes.map((box) => (
              <Col key={box.id} md={3} sm={6} xs={12} className="box-col">
                <Card
                  className="box"
                  id={`box-${box.id}`}
                  onClick={() => flipBox(box.id)}
                >
                  <div className="front">
                    <div className="icon-top-right">
                      <img src={reverse} alt="Extra Icon" />
                    </div>
                    <img src={box.icon} alt={box.title} />
                    <br />
                    <h3>{box.title}</h3>
                  </div>
                  <div className="back">
                    <p>{box.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <section className="testimoni">
        <Carousel
          indicators={true}
          controls={false}
          interval={carouselPlaying ? 15000 : null}
          fade={true} // Add fade effect for smooth transition
          className="carousel-container"
        >
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index} className="carousel-item">
              <img
                className="d-block w-100 carousel-image"
                src={testimonial.image}
                alt={`Slide ${index + 1}`}
              />
              <Carousel.Caption className="carousel-caption-custom">
                <h1 className="headingwhite">{testimonial.heading}</h1>
                <p className="testimobpara">{testimonial.text}</p>
                <p className="author-text">{testimonial.author}</p>
                <p className="none"></p>
                <p
                  style={{ fontSize: "11.5px" }}
                  className="testimobpara dispara"
                >
                  <i>{testimonial.disclaimer}</i>
                </p>
                <div
                  className="disclaimer"
                  style={{ gap: "10px", display: "flex" }}
                >
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => handleOpen(testimonial.videoUrl)}
                  >
                    Watch Video
                  </Button>
                  {(index === 0 || index === 1 || index === 2) && (
                    <a href={testimonial.pageUrl}>
                      <Button size="sm" variant="light">
                        Read More
                      </Button>
                    </a>
                  )}
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <VideoModal open={open} handleClose={handleClose} videoUrl={videoUrl} />
      </section>

      <div className="space"></div>

      <section className="demo-section">
        <div className="demo-content">
          <h1>Supercharge Your Syndicated Lending Now!</h1>
          <HrLines />
          <div className="space"></div>
          <Button size="small" color="primary" onClick={handleShow}>
            Product Demo
          </Button>
          <SyndicatedPopup show={showPopup} handleClose={handleClose} />
        </div>
      </section>
    </div>
  );
};

export default CyncSpreading;
