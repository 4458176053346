const circleuptestimonial = [
    {
        image: '/background/testimonialbg3.png',
        heading: 'Customer Testimonial',
        text: '“Cync created a customized solution for us, that reduced our costs from having an external vendor manage our loans to having it all in one application. We can service more loans with the same amount of staff.”',
        author: '- Jon Finley, Vice President of Operations and Strategy, CircleUp',
        videoUrl: 'https://player.vimeo.com/video/518242397?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t=',
        pageUrl: '/customer-stories/parabilis',
      },
  ];

  export default circleuptestimonial;
  