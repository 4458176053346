import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { datadogRum } from '@datadog/browser-rum';

// datadogRum.init({
//   applicationId: 'f8cccee9-6312-49cb-b40b-7c64e45d5343',
//   clientToken: 'pub6f9dbdb85b49ae326fc50214841c0dc8',
//   // `site` refers to the Datadog site parameter of your organization
//   // see https://docs.datadoghq.com/getting_started/site/
//   site: 'datadoghq.com',
//   service: 'cync-website',
//   env: 'dev',
//   // Specify a version number to identify the deployed version of your application in Datadog
//   // version: '1.0.0',
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 20,
//   defaultPrivacyLevel: 'mask-user-input',
//   startSessionReplayRecordingManually: true,
// });

// datadogRum.startSessionReplayRecording();

const root = createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
      <GoogleReCaptchaProvider reCaptchaKey="6LdKfxgjAAAAAPO0jJTu6rbM0q8X8agpHUjBDsiA">
    <App />
    </GoogleReCaptchaProvider>
  </StrictMode>
);

