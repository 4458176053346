import React, { useState, useEffect } from "react";
import "./Parabilis.css";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import Button from "../../../Button";
import HrLines from "../../../designs/HrLines";
import Typography from "@mui/material/Typography";
import VideoModal from "../../../VideoModal";
import testimonials from "../../../../Data/testimonialsData.js";
import parabilistestimonial from "../../../../Data/parabilisdata.js";
import { Helmet } from "react-helmet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import about from "./about.png";
import challenge from "./challenge.png";
import solution from "./solution.png";
import business from "./business.png";
import ParabilisPopup from "../../../popup/LOS/ParabilisPopup.jsx";
import { useNavigate } from "react-router-dom";

const Parabilis = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const handleStepClick = (index) => {
    if (activeStep === index) {
      setActiveStep(null);
    } else {
      setActiveStep(index);
      setLastActiveStep(index);
    }
  };
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();
 
   const handleShow = () =>{
     // Leadfeeder tracking
     window.history.pushState({}, "", "/customer-stories/parabilis/form");
     // Push event to Google Analytics / Leadfeeder (if using Google Tag Manager)
     window.dataLayer = window.dataLayer || [];
     window.dataLayer.push({
       event: "popup_opened"
     });
   
     // If using Google Analytics (GA4)
     if (window.gtag) {
       window.gtag("event", "popup_opened", {
         event_category: "Popup",
         event_label: "Popup Form Opened",
       });
     }
   
     setShowPopup(true);
   };
   const handleClose = () => {
     navigate(-1);
     setShowPopup(false);
     setOpen(false);
     setVideoUrl("");
     setCarouselPlaying(true);
   };
 

  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  return (
    <div>
      <Helmet>
        <title>Customer Stories - Cync Software®</title>
        <meta
          name="description"
          content="Automate collateral evaluation and borrowing base calculations with our industry-leading asset-based lending platform. Schedule a demo!"
        />
      </Helmet>

      <section className="Parabilis-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h4
                  style={{
                    color: "#E93740",
                    fontSize: "50px",
                    fontWeight: "700",
                  }}
                >
                  Customer Story
                </h4>
                <h1 className="lostopheading">
                  How Parabilis Gained Granular <br />
                  Insights Into Borrower’s <br />
                  Receivables Using Cync ABL
                </h1>
                <br />
                <span className="benefitshead">
                  <div className="benefitsgrids" style={{ width: "200px" }}>
                    <div className="icon">
                      <img
                        src={require("../../../../assets/icons/para1.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{height:'12px'}}></div>
                    <div className="text">
                      <h5 className="csheads">
                        Granular Collateral Segregation
                      </h5>
                    </div>
                  </div>
                  <div className="benefitsgrids" style={{ width: "230px" }}>
                    <div className="icon">
                      <img
                        src={require("../../../../assets/icons/para2.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{height:'12px'}}></div>
                    <div className="text">
                      <h5 className="csheads">
                        Increased Transparency for Clients
                      </h5>
                    </div>
                  </div>
                  <div className="benefitsgrids" style={{ width: "110px" }}>
                    <div className="icon">
                      <img
                        src={require("../../../../assets/icons/para3.png")}
                        alt="Icon 1"
                      />
                    </div>
                    <div style={{height:'12px'}}></div>
                    <div className="text">
                      <h5 className="csheads">On-Demand Scalability</h5>
                    </div>
                  </div>
                </span>
                <div style={{height:'12px'}}></div>
                <br />
                <Button size="small" color="primary" onClick={handleShow}>
                  Achieve Similar Results
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="space"></div>

      <section className="container">
        <div className="sections">
          <div className="sections-content">
            <h1>About Our Client</h1>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            >
              <hr
                style={{
                  width: "75px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
              <hr
                style={{
                  width: "15px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
            </div>
          </div>
        </div>
        <div className="sections">
          <div className="sections-content">
            <p>
              Parabilis, a financial services institution based out of McLean,
              Virginia, is dedicated to providing government contractors with
              better access to funds through a transparent and client-focused
              approach. Their commitment to honesty, responsiveness, and quick
              turnaround sets them apart in an industry where financial traps
              and hidden fees are often the norm. At Parabilis, what you see is
              exactly what you get, ensuring trust and clarity for their
              customers.
            </p>
          </div>
          <div className="sections-image">
            <img src={about} alt="Placeholder" />
          </div>
        </div>
      </section>

      <div className="space"></div>

      <section className="bg">
        <section className="container">
          <div className="sections">
            <div className="sections-image"></div>
            <div className="sections-content">
              <h1>The Challenge</h1>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              >
                <hr
                  style={{
                    width: "75px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
                <hr
                  style={{
                    width: "15px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="sections">
            <div className="sections-image">
              <img src={challenge} alt="Placeholder" />
            </div>
            <div className="sections-content">
              <p>
                Parabilis faced significant hurdles in managing their loan
                portfolio efficiently. Their manual, spreadsheets-based
                processes were cumbersome and error-prone, making it difficult
                to scale operations or gain deep, actionable insights.
                Additionally, most ABL software solutions they evaluated adopted
                a cookie-cutter approach and fell short of providing the
                flexibility needed to customize reports and analyze
                client-specific data effectively. The key challenges Parabilis
                encountered in their loan portfolio management journey were:
              </p>
              <ul>
                <li>
                  <b>Limited Reporting and Insights</b>: Evaluated solutions
                  lacked the ability to drill down into loan specifics, making
                  it impossible to generate customized, client-level reports.
                </li>
                <li>
                  <b>Unclear Borrowing Base Calculations</b>: Customers
                  struggled to categorize receivables accurately, making it
                  difficult to understand the borrowing base determination
                  process.
                </li>
                <li>
                  <b>Inefficiency and High Risk of Errors</b>: Manual processes
                  not only slowed operations but also increased the likelihood
                  of errors, hindering decision-making and operational growth.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
      <div className="space"></div>

      <section className="container">
        <div className="sections">
          <div className="sections-contents">
            <h1>The Cync Solution</h1>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            >
              <hr
                style={{
                  width: "75px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
              <hr
                style={{
                  width: "15px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
            </div>
          </div>
        </div>
        <div className="sections">
          <div className="sections-content">
            <p>
              Cync ABL’s high flexibility enabled Parabilis to customize a
              solution perfectly suited to meet their unique needs:
            </p>
            <ul>
              <li>
                <b>Granular Collateral Segregation</b>: Borrower collateral
                could now be divided into distinct categories such as billed
                receivables, unbilled receivables, work-in-process, and delivery
                orders. This enabled precise calculation of the borrowing base.
              </li>
              <li>
                <b>Transparency for Clients</b>: The borrower portal allowed
                clients to upload documents, view receivables categorized by
                stages, and track borrowing limits in real-time. Borrowers could
                even sign borrowing base certificates electronically,
                streamlining the process further.
              </li>
              <li>
                <b>On-Demand Scalability</b>: The cloud-based system ensured
                that Parabilis could scale their operations seamlessly as their
                portfolio expanded.
              </li>
            </ul>
          </div>
          <div className="sections-image">
            <img src={solution} alt="Placeholder" />
          </div>
        </div>
        <br />
      </section>
      <div className="space"></div>

      <section className="bg">
        <section className="container">
          <div className="sections">
            <div className="sections-image"></div>
            <div className="sections-content">
              <h1>Business Impact</h1>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              >
                <hr
                  style={{
                    width: "75px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
                <hr
                  style={{
                    width: "15px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="sections">
            <div className="sections-image">
              <img src={business} alt="Placeholder" />
            </div>
            <div className="sections-content">
              <p>
                By implementing Cync ABL, Parabilis achieved significant
                operational improvements, including:
              </p>
              <ul>
                <li>
                  <b>Accurate Borrowing Base Calculations</b>: Enhanced ability
                  to split collateral into buckets and assess risks for more
                  accurate borrowing base evaluations.
                </li>
                <li>
                  <b>Customized Insights</b>: Access to detailed reports and
                  client-specific insights at both the loan and portfolio
                  levels.
                </li>
                <li>
                  <b>Enhanced Transparency</b>: Better visibility for clients
                  into receivables and borrowing limits, fostering greater
                  confidence.
                </li>
                <li>
                  <b>Improved Scalability</b>: A cloud-based system ensuring
                  readiness for future growth.
                </li>
              </ul>
            </div>
          </div>
          <br />
        </section>
      </section>

      <section className="testimoni">
        <Carousel
          indicators={false}
          controls={false}
          interval={carouselPlaying ? 15 : null}
          fade={true} // Add fade effect for smooth transition
          className="carousel-container"
        >
          {parabilistestimonial.map((parabilistestimonial, index) => (
            <Carousel.Item key={index} className="carousel-item">
              <img
                className="d-block w-100 carousel-image"
                src={parabilistestimonial.image}
                alt={`Slide ${index + 1}`}
              />
              <Carousel.Caption className="carousel-caption-custom">
                <h1 className="headingwhite">{parabilistestimonial.heading}</h1>
                <p className="testimobpara">{parabilistestimonial.text}</p>
                <p className="author-text">{parabilistestimonial.author}</p>
                <p className="none"></p>
                <p
                  style={{ fontSize: "11.5px" }}
                  className="testimobpara dispara"
                >
                  <i>{parabilistestimonial.disclaimer}</i>
                </p>
                <div className="disclaimer">
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => handleOpen(parabilistestimonial.videoUrl)}
                  >
                    Watch Video
                  </Button>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <VideoModal open={open} handleClose={handleClose} videoUrl={videoUrl} />
      </section>

      <div className="space"></div>

      <section className="demo-section">
        <div className="demo-content">
          <h1>Supercharge Your Lending Now!</h1>
          <HrLines />
          <div className="space"></div>
          <Button size="small" color="primary" onClick={handleShow}>
            Schedule a Demo
          </Button>
          <ParabilisPopup show={showPopup} handleClose={handleClose} />
        </div>
      </section>
    </div>
  );
};

export default Parabilis;
