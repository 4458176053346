
const insightsData = [
    {
      id: 1,
      imageName: "14.png",
      date: "29 May, 2024",
      title: "Simplifying Compliance for Asset-Based Lending",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/simplifying-compliance-for-asset-based-lending",
      slug: "simplifying-compliance-for-asset-based-lending",
      filename: "post1.md",
      backgroundImage: "simplifying-compliance-for-asset-based-lending.jpg", 
      boxName: "BLOG",
      tags: ["Cync ABL"],
      keywords: ["Asset-Based Lending", "ABL solution", "automated audit tools", "asset-based lending software for banks" ]
    },
    {
      id: 2,
      imageName: "13.png",
      date: "22 May, 2024",
      title: "Modernizing Underwriting with an Agile Approach",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/modernizing-underwriting-with-an-Agile-Approach",
      slug: "modernizing-underwriting-with-an-Agile-Approach",
      filename: "post2.md",
      backgroundImage: "modernizing-underwriting-with-an-Agile-Approach.jpg",
      boxName: "BLOG",
      tags: ["Cync LOS"],
      keywords:["loan origination system", "LOS software", "loan origination solution", "commercial loan origination software", "Loan Origination Software Solution" ]
    },
    {
      id: 3,
      imageName: "12.png",
      date: "15 May, 2024",
      title: "Spreading – The Analysis Complex",
      content: "Cync Spreading",
      buttonLabel: "Read More",
      buttonLink: "/insights/spreading-the-analysis-complex",
      slug: "spreading-the-analysis-complex",
      filename: "post3.md",
      backgroundImage: "spreading-the-analysis-complex.jpg",
      tags: ["Cync Spreading"],
      keywords: ["Financial Analysis", "automating financial spreading", "spreading solution", "automated spreading solutions" ]
    },
    {
        id: 4,
        imageName: "11.png",
        date: "08 May, 2024",
        title: "Automating Loan Origination Processes",
        content: "Cync LOS",
        buttonLabel: "Read More",
        buttonLink: "/insights/automating-loan-origination-processes",
        slug: "automating-loan-origination-processes",
        filename: "post4.md",
        backgroundImage: "automating-loan-origination-processes.jpg",
        tags: ["Cync LOS"],
         keywords: ["loan origination", "automated loan origination solution", "loan origination workflows", "automating loan origination systems" ]
      },
      {
        id: 5,
        imageName: "10.png",
        date: "01 May, 2024",
        title: "Locating Rent Roll Tenants with Enterprise Search is Now Possible",
        content: "Cync Spreading",
        buttonLabel: "Read More",
        buttonLink: "/insights/locating-rent-roll-tenants-with-enterprise-search-is-now-possible",
        slug: "locating-rent-roll-tenants-with-enterprise-search-is-now-possible",
        filename: "post5.md",
        backgroundImage: "locating-rent-roll-tenants-with-enterprise-search-is-now-possible.jpg",
        tags: ["Cync Spreading"],
        keywords: ["spreading software", "financial spreading software", "bank spreading software", "financial spreading" ]
      },
      {
        id: 6,
        imageName: "9.png",
        date: "24 April, 2024",
        title: "Innovative Lending Requires Innovative Solutions",
        content: "Cync ABL",
        buttonLabel: "Read More",
        buttonLink: "/insights/innovative-lending-requires-innovative-solutions",
        slug: "innovative-lending-requires-innovative-solutions",
        filename: "post6.md",
        backgroundImage: "innovative-lending-requires-innovative-solutions.jpg",
        tags: ["Cync ABL"],
        keywords: ["asset based lending software", "lending software solutions", "collateral monitoring", "ABL software"]
      },
      {
        id: 7,
        imageName: "8.png",
        date: "17 April, 2024",
        title: "Factoring – Automated Invoice Verification for Cost Optimization",
        content: "Cync Factoring",
        buttonLabel: "Read More",
        buttonLink: "/insights/factoring-automated-invoice-verification-for-cost-optimization",
        slug: "factoring-automated-invoice-verification-for-cost-optimization",
        filename: "post7.md",
        backgroundImage: "factoring-automated-invoice-verification-for-cost-optimization.jpg",
        tags: ["Cync Factoring"],
        keywords: ["Invoice Automation", "financial factoring solution", "factoring software", "invoice factoring software" ]
      },
      {
        id: 8,
        imageName: "7.png",
        date: "10 April, 2024",
        title: "Dual-Factor Risk Assessment: A New Standard in Loan Evaluation",
        content: "Cync LOS",
        buttonLabel: "Read More",
        buttonLink: "/insights/dual-factor-risk-assessment-a-new-standard-in-loan-evaluation",
        slug: "dual-factor-risk-assessment-a-new-standard-in-loan-evaluation",
        filename: "post8.md",
        backgroundImage: "dual-factor-risk-assessment-a-new-standard-in-loan-evaluation.jpg",
        tags: ["Cync LOS"],
        keywords: ["loan assessment software", "loan management software", "loan origination software", "LOS software"]
      },
    {
      id: 9,
      imageName: "6.png",
      date: "03 April, 2024",
      title: "Reporting as a Service: Partnering for Premium Insights",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/reporting-as-a-service-partnering-for-premium-insights",
      slug: "reporting-as-a-service-partnering-for-premium-insights",
      filename: "post9.md",
      backgroundImage: "reporting-as-a-service-partnering-for-premium-insights.jpg",
      tags: ["Cync LOS", "Cync Spreading"],
      keywords: ["financial reporting tools", "reporting as a service", "financial reporting as a service", "financial reporting services" ]
    },
    {
      id: 10,
      imageName: "5.png",
      date: "26 March, 2024",
      title: "Automate BBCs, Mitigate Risk",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/automate-bbcs-to-mitigate-risk",
      slug: "automate-bbcs-to-mitigate-risk",
      filename: "post10.md",
      backgroundImage: "automate-bbcs-to-mitigate-risk.jpg",
      tags: ["Cync ABL"],
      keywords: ["borrowing base certificate", "loan management software", "Automating BBC", "commercial loan monitoring tool" ]
    },
    {
      id: 11,
      imageName: "4.png",
      date: "12 March, 2024",
      title: "Factoring – Solving Problems Through Automation",
      content: "Cync Factoring",
      buttonLabel: "Read More",
      buttonLink: "/insights/factoring-solving-problems-through-automation",
      slug: "factoring-solving-problems-through-automation",
      filename: "post11.md",
      backgroundImage: "factoring-solving-problems-through-automation.jpg",
      tags: ["Cync Factoring"],
      keywords: ["invoice factoring platform", "invoice factoring software", "invoice discounting software", "invoice financing software", "factoring software", "factoring software systems" ]
    },
    {
      id: 12,
      imageName: "3.png",
      date: "27 February, 2024",
      title: "Analyzing the Benefits of Self-Service Administration for Loan Origination Software",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/analyzing-the-benefits-of-self-service-administration-for-loan-origination-software",
      slug: "analyzing-the-benefits-of-self-service-administration-for-loan-origination-software",
      filename: "post12.md",
      backgroundImage: "analyzing-the-benefits-of-self-service-administration-for-loan-origination-software.jpg",
      tags: ["Cync LOS"],
      keywords: ["loan origination software", "commercial loan origination software", "loan origination system software" , "loan underwriting software", "loan processing software", "loan origination system", "los system"]
    },
    {
      id: 13,
      imageName: "2.png",
      date: "13 February, 2024",
      title: "Unlocking Opportunities with Asset-Based Lending: A Brief Guide",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/unlocking-opportunities-with-asset-based-lending-a-brief-guide",
      slug: "unlocking-opportunities-with-asset-based-lending-a-brief-guide",
      filename: "post13.md",
      backgroundImage: "cync-asset-based-lending-abl.jpg",
      tags: ["Cync ABL"],
      keywords :["asset based lending software","ABL software","asset based lending solutions","ABL solutions","asset-based lending software for banks"]
    },
    {
      id: 14,
      imageName: "1.png",
      date: "13 February, 2024",
      title: "Streamlining Efficiency: The High Cost of Manual Data Entry in Commercial Spreading",
      content: "Cync Spreading",
      buttonLabel: "Read More",
      buttonLink: "/insights/streamlining-efficiency-the-high-cost-of-manual-data-entry-in-commercial-spreading",
      slug: "streamlining-efficiency-the-high-cost-of-manual-data-entry-in-commercial-spreading",
      filename: "post14.md",
      backgroundImage: "cync-spreadings.jpg",
      tags: ["Cync Spreading"],
      keywords: ["automated spreading","automated financial spreading","financial spreading automation","spreading automation","financial spreading tools", "financial spreading software for banks"]
    },
    {
      id: 15,
      imageName: "FinancialFactoring.png",
      date: "5 June, 2024",
      title: "Revolutionizing Financial Factoring",
      content: "Cync Factoring",
      buttonLabel: "Read More",
      buttonLink: "/insights/revolutionizing-financial-factoring",
      slug: "revolutionizing-financial-factoring",
      filename: "post15.md",
      backgroundImage: "revolutionizing-financial-factoring.jpg",
      tags: ["Cync Factoring"],
      boxName: "BLOG",
      keywords:["digital transformation in factoring", "invoice factoring software", "factoring solutions", "factoring process" ]
    },
    {
      id: 16,
      imageName: "Revolutionizing.png",
      date: "12 June, 2024",
      title: "Revolutionizing Financial Spreading: The Impact of Automation and AI",
      content: "Cync Factoring",
      buttonLabel: "Read More",
      buttonLink: "/insights/revolutionizing-financial-spreading-the-impact-of-automation-and-ai",
      slug: "revolutionizing-financial-spreading-the-impact-of-automation-and-ai",
      filename: "post16.md",
      backgroundImage: "revolutionizing-financial-spreading-the-impact-of-automation-and-ai.jpg",
      tags: ["Cync Spreading"],
      boxName: "BLOG",
      keywords: ["automated spreading", "financial spreading automation", "automated financial spreading", "financial spreading software for banks"]
    },
    {
      id: 17,
      imageName: "17.png",
      date: "19 June, 2024",
      title: "The Power of Spreading Automation and Machine Learning in Data Standardization",
      content: "Cync Spreading",
      buttonLabel: "Read More",
      buttonLink: "/insights/spreading-automation-and-machine-learning-in-data-standardization",
      slug: "spreading-automation-and-machine-learning-in-data-standardization",
      filename: "post17.md",
      backgroundImage: "spreading-automation-and-machine-learning-in-data-standardization.jpg",
      tags: ["Cync Spreading"],
      boxName: "BLOG",
      keywords: ["spreading software", "financial spreading automation", "financial analysis", "financial spreading software for banks"]
    },
    {
      id: 18,
      imageName: "strengthening-loan-origination-security-automated-fraud-prevention.jpg",
      date: "27 June, 2024",
      title: "Strengthening Loan Origination Security: Automated Fraud Prevention",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/strengthening-loan-origination-security-automated-fraud-prevention",
      slug: "strengthening-loan-origination-security-automated-fraud-prevention",
      filename: "post18.md",
      backgroundImage: "strengthening-loan-origination-security-automated-fraud-prevention-banner.png",
      tags: ["Cync LOS"],
      boxName: "BLOG",
      keywords: ["loan origination system", "loan origination software", "loan origination system software", "loan origination software for banks", "digital lending solutions"]
    },
    {
      id: 19,
      imageName: "embracing-financial-freedom-automating-loan-origination.jpg",
      date: "3 July, 2024",
      title: "Embracing Financial Freedom: Automating Loan Origination ",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/embracing-financial-freedom-automating-loan-origination",
      slug: "embracing-financial-freedom-automating-loan-origination",
      filename: "post19.md",
      backgroundImage: "embracing-financial-freedom-automating-loan-origination-banner.jpg",
      tags: ["Cync LOS"],
      boxName: "BLOG",
      keywords: ["automating loan origination", "automation in loan origination", "loan origination software", "loan origination solutions"]
    },
    {
      id: 20,
      imageName: "unlocking-financial-efficiency-how-automating-factoring-optimizes-cash-flow-management.jpg",
      date: "10 July, 2024",
      title: "Unlocking Financial Efficiency: How Automating Factoring Optimizes Cash Flow Management",
      content: "Cync Factoring",
      buttonLabel: "Read More",
      buttonLink: "/insights/unlocking-financial-efficiency-how-automating-factoring-optimizes-cash-flow-management",
      slug: "unlocking-financial-efficiency-how-automating-factoring-optimizes-cash-flow-management",
      filename: "post20.md",
      backgroundImage: "unlocking-financial-efficiency-how-automating-factoring-optimizes-cash-flow-management-banner.jpg",
      tags: ["Cync Factoring"],
      boxName: "BLOG",
      keywords: ["factoring software", "factoring solution", "accounts receivable software", "invoice factoring", "invoice factoring software"]
    },
    {
      id: 21,
      imageName: "industry-specific-lending-in-asset-based-lending-tailoring-financing-solutions-for-every-sector.jpg",
      date: "17 July, 2024",
      title: "Industry-Specific Lending in Asset-Based Lending: Tailoring Financing Solutions for Every Sector",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/industry-specific-lending-in-asset-based-lending-tailoring-financing-solutions-for-every-sector",
      slug: "industry-specific-lending-in-asset-based-lending-tailoring-financing-solutions-for-every-sector",
      filename: "post21.md",
      backgroundImage: "industry-specific-lending-in-asset-based-lending-tailoring-financing-solutions-for-every-sector-banner.jpg",
      tags: ["Cync ABL"],
      boxName: "BLOG",
      keywords: ["asset based lending software", "ABL software", "ABL solutions","commercial lending software", "financing solutions", "automated ABL systems"]
    },
    {
      id: 22,
      imageName: "mastering-financial-spreading-how-automation-elevates-expertise-and-efficiency.jpg",
      date: "24 July, 2024",
      title: "Mastering Financial Spreading: How Automation Elevates Expertise and Efficiency",
      content: "Cync Spreading",
      buttonLabel: "Read More",
      buttonLink: "/insights/mastering-financial-spreading-how-automation-elevates-expertise-and-efficiency",
      slug: "mastering-financial-spreading-how-automation-elevates-expertise-and-efficiency",
      filename: "post22.md",
      backgroundImage: "mastering-financial-spreading-how-automation-elevates-expertise-and-efficiency-banner.jpg",
      tags: ["Cync Spreading"],
      boxName: "BLOG",
      keywords: ["Automating financial spreading", "financial spreading automation", "financial spreading software", "spreading automation solutions", "automated spreading software" ]
    },
    {
      id: 23,
      imageName: "streamlining-success-automating-loan-origination-with-open-banking-and-apis.jpg",
      date: "31 July, 2024",
      title: "Streamlining Success: Automating Loan Origination with Open Banking and APIs",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/streamlining-success-automating-loan-origination-with-open-banking-and-apis",
      slug: "streamlining-success-automating-loan-origination-with-open-banking-and-apis",
      filename: "post23.md",
      backgroundImage: "streamlining-success-automating-loan-origination-with-open-banking-and-apis-banner.jpg",
      tags: ["Cync LOS"],
      boxName: "BLOG",
      keywords: ["loan origination system", "automating loan origination", "loan origination automation", "loan automation", "loan origination process" ]
    },
    {
      id: 24,
      imageName: "empowering-client-selection-how-automating-asset-based-lending-transforms-borrower-relationships.jpg",
      date: "7 August, 2024",
      title: "Empowering Client Selection: How Automating Asset-Based Lending Transforms Borrower Relationships ",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/empowering-client-selection-how-automating-asset-based-lending-transforms-borrower-relationships",
      slug: "empowering-client-selection-how-automating-asset-based-lending-transforms-borrower-relationships",
      filename: "post24.md",
      backgroundImage: "empowering-client-selection-how-automating-asset-based-lending-transforms-borrower-relationships-banner.jpg",
      tags: ["Cync LOS"],
      boxName: "BLOG",
      keywords: ["asset-based lending", "ABL software", "commercial lending software", "automated ABL solutions" ]
    },
    {
      id: 25,
      imageName: "accelerating-sba-7(a)-working-capital-loans-with-asset-based-lending-software.jpg",
      date: "14 August, 2024",
      title: "Accelerating SBA 7(a) Working Capital Loans with Asset-Based Lending Software",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/accelerating-sba-7(a)-working-capital-loans-with-asset-based-lending-software",
      slug: "accelerating-sba-7(a)-working-capital-loans-with-asset-based-lending-software",
      filename: "post25.md",
      backgroundImage: "accelerating-banner.jpg",
      tags: ["Cync ABL"],
      boxName: "BLOG",
      keywords: [ "SBA 7(a) working capital loans" ,"SBA loan calculator","SBA 7a working capital loans requirements","SBA 7(a) loan requirements","SBA 7a loan" ]
    },
    {
      id: 26,
      imageName: "why-automation-is-crucial-for-modern-asset-based-lending-operations.jpg",
      date: "21 August, 2024",
      title: "Why Automation is Crucial for Modern Asset-Based Lending Operations",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/why-automation-is-crucial-for-modern-asset-based-lending-operations",
      slug: "why-automation-is-crucial-for-modern-asset-based-lending-operations",
      filename: "post26.md",
      backgroundImage: "why-automation-is-crucial-for-modern-asset-based-lending-operations-banner.jpg",
      tags: ["Cync ABL"],
      boxName: "BLOG",
      keywords: [ "Asset based lending software", "Asset based lending solution", "borrowing base software", "borrowing base certificates" ]
    },
    {
      id: 27,
      imageName: "common-challenges-banks-face-in-commercial-real-estate-loan-origination.jpg",
      date: "30 August, 2024",
      title: "Common Challenges in Commercial Real Estate Loan Origination",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/common-challenges-in-commercial-real-estate-loan-origination",
      slug: "common-challenges-in-commercial-real-estate-loan-origination",
      filename: "post27.md",
      backgroundImage: "common-challenges-banks-face-in-commercial-real-estate-loan-origination-banner.jpg",
      tags: ["Cync LOS"],
      boxName: "BLOG",
      keywords: [ "commercial real estate loan origination","commercial mortgage origination","commercial real estate origination","CRE loan origination" ]
    },
    {
      id: 28,
      imageName: "the-future-of-financial-spreading-embracing-automation-for-better-banking.jpg",
      date: "11 September, 2024",
      title: "The Future of Financial Spreading: Embracing Automation for Better Banking",
      content: "Cync Spreading",
      buttonLabel: "Read More",
      buttonLink: "/insights/the-future-of-financial-spreading-embracing-automation-for-better-banking",
      slug: "the-future-of-financial-spreading-embracing-automation-for-better-banking",
      filename: "post28.md",
      backgroundImage: "the-future-of-financial-spreading-embracing-automation-for-better-banking-banner.jpg",
      tags: ["Cync Spreading"],
      boxName: "BLOG",
      keywords: [ "automated financial spreading", "financial spreading automation", "financial spreading software", "automated financial spreading in banking"   ]
    },
    {
      id: 29,
      imageName: "manual-vs-automated-borrowing-base-certificates-which-approach-is-right-for-your-business.jpg",
      date: "20 September, 2024",
      title: "Manual vs. Automated Borrowing Base Certificates: Which Approach is Right for Your Business?",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/manual-vs-automated-borrowing-base-certificates-which-approach-is-right-for-your-business",
      slug: "manual-vs-automated-borrowing-base-certificates-which-approach-is-right-for-your-business",
      filename: "post29.md",
      backgroundImage: "manual-vs-automated-borrowing-base-certificates-which-approach-is-right-for-your-business-banner.jpg",
      tags: ["Cync ABL"],
      boxName: "BLOG",
      keywords: [ "automated borrowing base certificates", "borrowing base certificate generation", "borrowing base certificate automation"   ]
    },
    {
      id: 30,
      imageName: "how-factoring-solutions-reduce-financial-stress-for-business-owners.jpg",
      date: "24 September, 2024",
      title: "How Factoring Solutions Reduce Financial Stress for Business Owners",
      content: "Cync Factoring",
      buttonLabel: "Read More",
      buttonLink: "/insights/how-factoring-solutions-reduce-financial-stress-for-business-owners",
      slug: "how-factoring-solutions-reduce-financial-stress-for-business-owners",
      filename: "post30.md",
      backgroundImage: "how-factoring-solutions-reduce-financial-stress-for-business-owners-banner.jpg",
      tags: ["Cync Factoring"],
      boxName: "BLOG",
      keywords: [ "factoring software", "invoice factoring software", "invoice financing software", "invoice factoring platform"    ]
    },
    {
      id: 31,
      imageName: "streamlining-asset-based-lending-with-cutting-edge-technology.jpg",
      date: "03 October, 2024",
      title: "Streamlining Asset-Based Lending with Cutting-Edge Technology",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/streamlining-asset-based-lending-with-cutting-edge-technology",
      slug: "streamlining-asset-based-lending-with-cutting-edge-technology",
      filename: "post31.md",
      backgroundImage: "streamlining-asset-based-lending-with-cutting-edge-technology-banner.jpg",
      tags: ["Cync ABL"],
      boxName: "BLOG",
      keywords: [ "asset based lending software", "ABL software", "asset based lending solution","commercial lending platform"    ]
    },
    {
      id: 32,
      imageName: "transforming-underwriting-the-impact-of-automated-spreading-on-credit-decisioning.jpg",
      date: "09 October, 2024",
      title: "Transforming Underwriting: The Impact of Automated Spreading on Credit Decisioning ",
      content: "Cync Spreading",
      buttonLabel: "Read More",
      buttonLink: "/insights/transforming-underwriting-the-impact-of-automated-spreading-on-credit-decisioning",
      slug: "transforming-underwriting-the-impact-of-automated-spreading-on-credit-decisioning",
      filename: "post32.md",
      backgroundImage: "transforming-underwriting-the-impact-of-automated-spreading-on-credit-decisioning-banner.jpg",
      tags: ["Cync Spreading"],
      boxName: "BLOG",
      keywords: [ "automated spreading", "automated financial spreading", "spreading automation", "financial spreading software", "rent roll automation", "automate rent roll processing", "automate CRE rent rolls" ]
    },
    {
      id: 33,
      imageName: "breaking-down-the-complexities-of-financial-spreading-in-commercial-real-estate-how-automation-can-streamline-the-process.jpg",
      date: "29 October, 2024",
      title: "Breaking Down the Complexities of Financial Spreading in Commercial Real Estate: How Automation Can Streamline the Process",
      content: "Cync Spreading",
      buttonLabel: "Read More",
      buttonLink: "/insights/breaking-down-the-complexities-of-financial-spreading-in-commercial-real-estate-how-automation-can-streamline-the-process",
      slug: "breaking-down-the-complexities-of-financial-spreading-in-commercial-real-estate-how-automation-can-streamline-the-process",
      filename: "post33.md",
      backgroundImage: "breaking-down-the-complexities-of-financial-spreading-in-commercial-real-estate-how-automation-can-streamline-the-process-banner.jpg",
      tags: ["Cync Spreading"],
      boxName: "BLOG",
      keywords: [ "financial spreading in commercial real estate", "automate financial spreading in commercial real estate", "financial spreading automation", "automated financial spreading" ]
    },
    {
      id: 34,
      imageName: "the-essential-guide-to-portfolio-management-in-commercial-loan-origination-why-its-a-game-changer.png",
      date: "08 November, 2024",
      title: "The Essential Guide to Portfolio Management in Commercial Loan Origination: Why It’s a Game-Changer",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/the-essential-guide-to-portfolio-management-in-commercial-loan-origination-why-its-a-game-changer",
      slug: "the-essential-guide-to-portfolio-management-in-commercial-loan-origination-why-its-a-game-changer",
      filename: "post34.md",
      backgroundImage: "the-essential-guide-to-portfolio-management-in-commercial-loan-origination-why-its-a-game-changer-banner.jpg",
      tags: ["Cync LOS"],
      boxName: "BLOG",
      keywords: [ "loan portfolio management system", "commercial loan portfolio management", "commercial loan portfolio strategy", "commercial loan origination software", "commercial loan origination system" ]
    },
    {
      id: 35,
      imageName: "why-commercial-lenders-are-turning-to-saas-for-loan-origination.jpg",
      date: "14 November, 2024",
      title: "Why Commercial Lenders Are Turning to SaaS for Loan Origination",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/why-commercial-lenders-are-turning-to-saas-for-loan-origination",
      slug: "why-commercial-lenders-are-turning-to-saas-for-loan-origination",
      filename: "post35.md",
      backgroundImage: "why-commercial-lenders-are-turning-to-saas-for-loan-origination-banner.jpg",
      tags: ["Cync LOS"],
      boxName: "BLOG",
      keywords: [ "commercial loan origination", "commercial loan origination software", "commercial loan origination system", "commercial loan origination software for brokers", "commercial loan processing software" ]
    },
    {
      id: 36,
      imageName: "how-automated-factoring-solutions-are-supporting-modern-b2b-commerce.jpg",
      date: "21 November, 2024",
      title: "How Automated Factoring Solutions Are Supporting Modern B2B Commerce",
      content: "Cync Factoring",
      buttonLabel: "Read More",
      buttonLink: "/insights/how-automated-factoring-solutions-are-supporting-modern-b2b-commerce",
      slug: "how-automated-factoring-solutions-are-supporting-modern-b2b-commerce",
      filename: "post36.md",
      backgroundImage: "how-automated-factoring-solutions-are-supporting-modern-b2b-commerce-banner.jpg",
      tags: ["Cync Factoring"],
      boxName: "BLOG",
      keywords: [ "automated factoring solutions", "factoring solutions", "factoring software", "factoring invoices", "invoice factoring software", "invoice financing software", "factoring solutions for b2b" ]
    },
    {
      id: 37,
      imageName: "automation-in-abl-audits-speeding-up-the-compliance-process-for-enhanced-lending-security.jpg",
      date: "25 November, 2024",
      title: "Automation in ABL Audits: Speeding Up the Compliance Process for Enhanced Lending Security",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/automation-in-abl-audits-speeding-up-the-compliance-process-for-enhanced-lending-security",
      slug: "automation-in-abl-audits-speeding-up-the-compliance-process-for-enhanced-lending-security",
      filename: "post37.md",
      backgroundImage: "automation-in-abl-audits-speeding-up-the-compliance-process-for-enhanced-lending-security-banner.jpg",
      tags: ["Cync ABL"],
      boxName: "BLOG",
      keywords: [ "automation in abl audits", "abl automation", "asset-based lending software", "abl automation for lending security", "asset-based lending solution" ]
    },
    {
      id: 38,
      imageName: "accelerating-financial-analysis-how-customizable-spreading-templates-streamline-implementation.jpg",
      date: "05 December, 2024",
      title: "Accelerating Financial Analysis: How Customizable Spreading Templates Streamline Implementation",
      content: "Cync Spreading",
      buttonLabel: "Read More",
      buttonLink: "/insights/accelerating-financial-analysis-how-customizable-spreading-templates-streamline-implementation",
      slug: "accelerating-financial-analysis-how-customizable-spreading-templates-streamline-implementation",
      filename: "post38.md",
      backgroundImage: "accelerating-financial-analysis-how-customizable-spreading-templates-streamline-implementation-banner.jpg",
      tags: ["Cync Spreading"],
      boxName: "BLOG",
      keywords: [ "financial analysis software", "automated financial spreading", "financial analysis platform", "financial analysis tools", "financial spreading automation"  ]
    },
    {
      id: 39,
      imageName: "from-hours-to-minutes-how-automation-in-financial-spreading-transforms-productivity.jpg",
      date: "11 December, 2024",
      title: "From Hours to Minutes: How Automation in Financial Spreading Transforms Productivity",
      content: "Cync Spreading",
      buttonLabel: "Read More",
      buttonLink: "/insights/from-hours-to-minutes-how-automation-in-financial-spreading-transforms-productivity",
      slug: "from-hours-to-minutes-how-automation-in-financial-spreading-transforms-productivity",
      filename: "post39.md",
      backgroundImage: "from-hours-to-minutes-how-automation-in-financial-spreading-transforms-productivity-banner.jpg",
      tags: ["Cync Spreading"],
      boxName: "BLOG",
      keywords: [ "financial spreading automation", "automate financial spreading", "spreading financial statements", "financial spreading platform", "financial spreading", "financial statement spreading"   ]
    },
    {
      id: 40,
      imageName: "streamlining-collateral-management-automating-ineligibles-and-sub-limits-in-asset-based-lending.jpg",
      date: "16 December, 2024",
      title: "Streamlining Collateral Management: Automating Ineligibles and Sub-Limits in Asset-Based Lending",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/streamlining-collateral-management-automating-ineligibles-and-sub-limits-in-asset-based-lending",
      slug: "streamlining-collateral-management-automating-ineligibles-and-sub-limits-in-asset-based-lending",
      filename: "post40.md",
      backgroundImage: "streamlining-collateral-management-automating-ineligibles-and-sub-limits-in-asset-based-lending-banner.jpg",
      tags: ["Cync ABL"],
      boxName: "BLOG",
      keywords: [ "automating ineligibles", "asset-based lending software", "collateral management", "collateral management solutions", "asset-based lending solution" ]
    },
    {
      id: 41,
      imageName: "accelerating-loan-product-launches-how-no-code-approach-revolutionizes-loan-origination.jpg",
      date: "24 December, 2024",
      title: "Accelerating Loan Product Launches: How No-Code Approach Revolutionizes Loan Origination",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/accelerating-loan-product-launches-how-no-code-approach-revolutionizes-loan-origination",
      slug: "accelerating-loan-product-launches-how-no-code-approach-revolutionizes-loan-origination",
      filename: "post41.md",
      backgroundImage: "accelerating-loan-product-launches-how-no-code-approach-revolutionizes-loan-origination-banner.jpg",
      tags: ["Cync LOS"],
      boxName: "BLOG",
      keywords: [ "no-code approach", "loan origination software with no-code", "digital lending with no-code", "loan origination software" ]
    },
    {
      id: 42,
      imageName: "comprehensive-collateral-monitoring-empowering-confident-lending-across-diverse-asset-types.jpg",
      date: "02 January, 2025",
      title: "Comprehensive Collateral Monitoring: Empowering Confident Lending Across Diverse Asset Types",
      content: "Cync ABL",
      buttonLabel: "Read More",
      buttonLink: "/insights/comprehensive-collateral-monitoring-empowering-confident-lending-across-diverse-asset-types",
      slug: "comprehensive-collateral-monitoring-empowering-confident-lending-across-diverse-asset-types",
      filename: "post42.md",
      backgroundImage: "comprehensive-collateral-monitoring-empowering-confident-lending-across-diverse-asset-types-banner.jpg",
      tags: ["Cync ABL"],
      boxName: "BLOG",
      keywords: [ "collateral monitoring software", "collateral management system", "collateral monitoring solution", "collateral tracker", "loan tracking software", "loan management software" ]
    },
    {
      id: 43,
      imageName: "tailoring-loan-origination-unlocking-flexibility-with-cync-lOS-microservices-library.jpg",
      date: "09 January, 2025",
      title: "Tailoring Loan Origination: Unlocking Flexibility With Cync LOS’s Microservices Library",
      content: "Cync LOS",
      buttonLabel: "Read More",
      buttonLink: "/insights/tailoring-loan-origination-unlocking-flexibility-with-cync-lOS-microservices-library",
      slug: "tailoring-loan-origination-unlocking-flexibility-with-cync-lOS-microservices-library",
      filename: "post43.md",
      backgroundImage: "tailoring-loan-origination-unlocking-flexibility-with-cync-lOS-microservices-library-banner.jpg",
      tags: ["Cync LOS"],
      boxName: "BLOG",
      keywords: [ "tailoring loan origination software", "tailoring loan management", "loan origination software customization", "tailoring los solutions", "custom loan origination software"  ]
    },
    {
      id: 44,
      imageName: "meeting-stakeholder-expectations-in-syndicated-lending-with-flexible-solutions.jpg",
      date: "16 January, 2025",
      title: "Meeting Stakeholder Expectations in Syndicated Lending with Flexible Solutions",
      content: "Cync Syndicated Lending",
      buttonLabel: "Read More",
      buttonLink: "/insights/meeting-stakeholder-expectations-in-syndicated-lending-with-flexible-solutions",
      slug: "meeting-stakeholder-expectations-in-syndicated-lending-with-flexible-solutions",
      filename: "post44.md",
      backgroundImage: "meeting-stakeholder-expectations-in-syndicated-lending-with-flexible-solutions-banner.jpg",
      tags: ["Cync Syndicated Lending"],
      boxName: "BLOG",
      keywords: [ "syndicated lending software", "syndicated lending solutions", "loan syndication software", "syndicated loan platform", "syndicated loan processing"   ]
    },
    {
      id: 45,
      imageName: "why-rent-roll-analysis-is-a-crucial-component-in-financial-spreading-automation-software-for-cre-lending.jpg",
      date: "23 January, 2025",
      title: "Why Rent Roll Analysis is a Crucial Component in Financial Spreading Automation Software For CRE Lending",
      content: "Cync Spreading",
      buttonLabel: "Read More",
      buttonLink: "/insights/why-rent-roll-analysis-is-a-crucial-component-in-financial-spreading-automation-software-for-cre-lending",
      slug: "why-rent-roll-analysis-is-a-crucial-component-in-financial-spreading-automation-software-for-cre-lending",
      filename: "post45.md",
      backgroundImage: "why-rent-roll-analysis-is-a-crucial-component-in-financial-spreading-automation-software-for-cre-lending-banner.jpg",
      tags: ["Cync Spreading"],
      boxName: "BLOG",
      keywords: [ "Rent Roll Analysis", "Financial Spreading", "Commercial Real Estate (CRE)", "Financial Spreading Automation", "Automated Financial Spreading" ]
    },
  ];

  export default insightsData;