import React, { useState, useEffect } from "react";
import "../../assets/css/CyncLOS.css";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import Button from "../Button";
import HrLines from "../designs/HrLines";
import Typography from "@mui/material/Typography";
import LOSPopup from "../popup/LOS/LOSPopup.jsx";
import VideoModal from "../VideoModal";
import testimonials from "../../Data/testimonialsData.js";
import { boxes } from "../../Data/boxesData.js";
import { steps } from "../../Data/stepsData.js";
import reverse from "../../assets/icons/reverse.png";
import { Helmet } from "react-helmet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import LosStepBoxes from "../StepBoxes/LosStepBoxes.jsx";
import { useNavigate } from "react-router-dom";

const flipBox = (id) => {
  const clickedBox = document.getElementById(`box-${id}`);
  const allBoxes = document.querySelectorAll(".box");

  // Remove 'flipped' class from all boxes except the clicked one
  allBoxes.forEach((box) => {
    if (box !== clickedBox) {
      box.classList.remove("flipped");
    }
  });

  // Toggle 'flipped' class for the clicked box
  clickedBox.classList.toggle("flipped");
};

const CyncLOS = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const handleStepClick = (index) => {
    if (activeStep === index) {
      setActiveStep(null);
    } else {
      setActiveStep(index);
      setLastActiveStep(index);
    }
  };

  const [showPopup, setShowPopup] = useState(false);

 const navigate = useNavigate();

  const handleShow = () =>{
    // Leadfeeder tracking
    window.history.pushState({}, "", "/cync-loan-origination-system-los/form");
    // Push event to Google Analytics / Leadfeeder (if using Google Tag Manager)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "popup_opened"
    });
  
    // If using Google Analytics (GA4)
    if (window.gtag) {
      window.gtag("event", "popup_opened", {
        event_category: "Popup",
        event_label: "Popup Form Opened",
      });
    }
  
    setShowPopup(true);
  };
  const handleClose = () => {
    navigate(-1);
    setShowPopup(false);
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };


  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  return (
    <div>
      <Helmet>
        <title>
          Commercial Loan Origination Software | Loan Origination System - Cync
          Software®
        </title>
        <meta
          name="description"
          content="Agile, scalable commercial loan origination system (LOS) accelerates lending without coding. Customize workflows, innovate with API-first, cloud-based solution. Schedule a demo!"
        />
      </Helmet>

      <section className="lostop-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h4
                  style={{
                    color: "#E93740",
                    fontSize: "50px",
                    fontWeight: "700",
                  }}
                >
                  Cync LOS
                </h4>
                <h1 className="lostopheading">
                  Highly Flexible and Scalable End-to-End Commercial Loan
                  Origination System to Fast-Track Lending
                </h1>
                <p className="toppara">
                  Design custom loan origination workflows for any risk appetite
                  with unmatched <br />
                  agility. Propel innovation with an API-first approach and
                  scale on demand with
                  <br /> an intelligent, cloud-based solution—all this, without
                  writing a line of code.
                </p>
                <Button size="small" color="primary" onClick={handleShow}>
                  Product Demo
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="space"></div>

      <section>
        <Container className="h-100">
          <h1 className="privacyheading">
            Supercharge Your Lending With Cync LOS
          </h1>
          <HrLines />
          <br />
          <p className="contentpara">
            Commercial customers are demanding swift lending decisions and
            real-time updates on their loan requests. However, legacy loan
            origination systems (LOS), with their one-size-fits-all approach,
            lack the flexibility to reconfigure workflows to meet customer
            expectations as well as to adapt to evolving regulations. As banks
            resort to quick fixes, they often increase the operational burden
            without achieving notable improvements in disbursement speed.
          </p>
          <p className="contentpara">
            Enter Cync LOS, an ultra-modern loan origination system that allows
            banks to launch new products and modify existing workflows in a
            matter of minutes. What’s more, with its top-notch artificial
            intelligence capabilities and a slick user interface, transform your
            productivity and accelerate your loan processing speed.
          </p>
        </Container>
      </section>
      <div className="space"></div>

      <section className="benefits-with-grids">
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/improvement.png")}
              alt="Icon 1"
            />
          </div>
          <div className="text">
            <h6>4X</h6>
            <h5>Back-Office Productivity Improvement</h5>
          </div>
        </div>
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/development.png")}
              alt="Icon 2"
            />
          </div>
          <div className="text">
            <h6>0%</h6>
            <h5>Development to Launch New Products</h5>
          </div>
        </div>
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/white360.png")}
              alt="Icon 3"
            />
          </div>
          <div className="text">
            <h6>360⁰ </h6>
            <h5>Insights Into Affiliated Entities</h5>
          </div>
        </div>
      </section>
      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Why Cync LOS?</h1>
          <HrLines />
          <div className="space"></div>
          <LosStepBoxes />
        </Container>
      </section>

      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Expansive Feature Set</h1>
          <HrLines />
          <div className="space"></div>
          <Row className="box-row">
            {boxes.map((box) => (
              <Col key={box.id} md={3} sm={6} xs={12} className="box-col">
                <Card
                  className="box"
                  id={`box-${box.id}`}
                  onClick={() => flipBox(box.id)}
                >
                  <div className="front">
                    <div className="icon-top-right">
                      <img src={reverse} alt="Extra Icon" />
                    </div>
                    <img src={box.icon} alt={box.title} />
                    <br />
                    <h3>{box.title}</h3>
                  </div>
                  <div className="back">
                    <p>{box.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <section className="testimoni">
        <Carousel
          indicators={true}
          controls={false}
          interval={carouselPlaying ? 15000 : null}
          fade={true} // Add fade effect for smooth transition
          className="carousel-container"
        >
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index} className="carousel-item">
              <img
                className="d-block w-100 carousel-image"
                src={testimonial.image}
                alt={`Slide ${index + 1}`}
              />
              <Carousel.Caption className="carousel-caption-custom">
                <h1 className="headingwhite">{testimonial.heading}</h1>
                <p className="testimobpara">{testimonial.text}</p>
                <p className="author-text">{testimonial.author}</p>
                <p className="none"></p>
                <p
                  style={{ fontSize: "11.5px" }}
                  className="testimobpara dispara"
                >
                  <i>{testimonial.disclaimer}</i>
                </p>
                <div
                  className="disclaimer"
                  style={{ gap: "10px", display: "flex" }}
                >
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => handleOpen(testimonial.videoUrl)}
                  >
                    Watch Video
                  </Button>
                  {(index === 0 || index === 1 || index === 2) && (
                    <a href={testimonial.pageUrl}>
                      <Button size="sm" variant="light">
                        Read More
                      </Button>
                    </a>
                  )}
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <VideoModal open={open} handleClose={handleClose} videoUrl={videoUrl} />
      </section>

      <div className="space"></div>

      <section className="demo-section">
        <div className="demo-content">
          <h1>Supercharge Your Loan Origination Now!</h1>
          <HrLines />
          <div className="space"></div>
          <Button size="small" color="primary" onClick={handleShow}>
            Product Demo
          </Button>
          <LOSPopup show={showPopup} handleClose={handleClose} />
        </div>
      </section>
    </div>
  );
};

export default CyncLOS;
