import React, { useState, useEffect } from "react";
import "./MainSection.css"; // Import CSS file for styling
import Button from "../Button";
import Newsletter from "../NewsLetter";
import ScheduleDemo from "../ScheduleDemo";
import HrLines from "../designs/HrLines";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import { Lendingdata } from "../../Data/boxesData.js";
import VideoModal from "../VideoModal";
import testimonials from "../../Data/testimonialsData.js";
import reverse from "../../assets/icons/reverse.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import insightsData from "../../Data/InsightsData";
import { isValidPath } from "../../utils/validation.jsx";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import AlertPopup from "../Alert/AlertPopup.jsx";

const flipBox = (id) => {
  const clickedBox = document.getElementById(`box-${id}`);
  const allBoxes = document.querySelectorAll(".box");

  // Remove 'flipped' class from all boxes except the clicked one
  allBoxes.forEach((box) => {
    if (box !== clickedBox) {
      box.classList.remove("flipped");
    }
  });

  // Toggle 'flipped' class for the clicked box
  clickedBox.classList.toggle("flipped");
};

const logos = [
  {
    src: "/logos/docusign.png",
    tooltip:
      "Avoid the hassle of printing and mailing physical documents for affixing signature.",
    link: "https://www.docusign.com/",
  },
  {
    src: "/logos/codat.png",
    tooltip:
      "Access borrowers’ financial information from accounting software in real-time.  ",
    link: "https://www.codat.io/",
  },
  {
    src: "/logos/experian.png",
    tooltip:
      "Enable faster, informed decision-making with easy access to credit reporting. ",
    link: "https://www.experian.com/",
  },
  {
    src: "/logos/salesforce.png",
    tooltip:
      "Enable customers to securely transfer data by efficiently managing data ingestion and validation processes, ensuring speed, agility, and reliability.",
    link: "https://www.salesforce.com/",
  },
  {
    src: "/logos/tableau.png",
    tooltip:
      "Create easy-to-use, lightweight reports and eye-catching visualizations with Tableau Data Prep and Tableau Cloud. ",
    link: "https://www.tableau.com/",
  },
  {
    src: "/logos/alloy.png",
    tooltip:
      "Automate and manage decisions for onboarding, continuous fraud detection, AML monitoring, and more. ",
    link: "https://www.alloy.com/",
  },
  {
    src: "/logos/fusion.png",
    tooltip:
      "Generate complete and compliant loan closing documentation from transaction data. ",
    link: "https://www.finastra.com/solutions/laserpro",
  },
  {
    src: "/logos/plaid.png",
    tooltip:
      "Access up-to-date information for financial analysis of borrowers.",
    link: "https://plaid.com/",
  },
  {
    src: "/logos/quickbooks.png",
    tooltip: "Import invoices directly from QuickBooks.",
    link: "https://quickbooks.intuit.com/",
  },
  {
    src: "/logos/rma.png",
    tooltip:
      'Import the latest ratios to compare a borrower"s financials against industry peers.',
    link: "https://www.rmahq.org/",
  },
  {
    src: "/logos/transunion.png",
    tooltip:
      "Enable faster, informed decision-making with easy access to credit reporting. ",
    link: "https://www.transunion.com/",
  },
  {
    src: "/logos/wolters.png",
    tooltip:
      "Generate compliant documents across asset classes that adapt to ever-changing regulatory requirements. ",
    link: "https://www.wolterskluwer.com/en/solutions/expere",
  },
];

const repeatedLogos = logos.concat(logos);

const heading = {
  display: "flex",
  justifyContent: "center" /* Horizontal alignment */,
  alignItems: "center",
  padding: "20px",
  textAlign: "center",
  top: "10px",
};

const MainSection = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleShow = () => setShowPopup(true);
  const handleClose = () => {
    setShowPopup(false);
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };

  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  const handleNavigation = (url) => {
    if (isValidPath(url)) {
      window.location.assign(url);
      window.location.reload();
    } else {
      console.warn("Invalid path:", url);
    }
  };

  // Sort insights data based on date in descending order
  const sortedInsightsData = [...insightsData].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  // Get the first three posts
  const recentPosts = sortedInsightsData.slice(0, 2);

  // Define the content for the third box
  const thirdBoxContent = {
    id: "special",
    date: "November 13-15, 2024",
    imageName: "rmaevent.png", // Replace with the correct image path
    content: "EVENTS",
    title: "SFNet's 80th Annual Convention",
    buttonLink: "/conference/2024/sfnet/",
    buttonLabel: "Read More",
  };

  return (
    <div>
      {/**benefits Section */}
      <section className="benefits-with-grids">
        <div className="benefitsgrid">
          <div className="icon">
            <img
              src={require("../../assets/icons/productivity.png")}
              alt="Icon 3"
            />
          </div>
          <div className="text">
            <h6>4X</h6>
            <h5>Productivity Boost</h5>
          </div>
        </div>
        <div className="benefitsgrid">
          <div className="icon">
            <img
              src={require("../../assets/icons/portfoliowhite.png")}
              alt="Icon 4"
            />
          </div>
          <div className="text">
            <h6>2X</h6>
            <h5>Portfolios per Analyst</h5>
          </div>
        </div>
        <div className="benefitsgrid">
          <div className="icon">
            <img
              src={require("../../assets/icons/labourcost.png")}
              alt="Icon 1"
            />
          </div>
          <div className="text">
            <h6>25%</h6>
            <h5>Reduction in Costs</h5>
          </div>
        </div>
        <div className="benefitsgrid">
          <div className="icon">
            <img
              src={require("../../assets/icons/customize.png")}
              alt="Icon 2"
            />
          </div>
          <div className="text">
            <h6>100%</h6>
            <h5>Customizable</h5>
          </div>
        </div>
      </section>

      <div className="space"></div>

      {/**Products Section */}
      <section style={heading}>
        <h1>Our Products</h1>
      </section>
      <HrLines />
      <br />

      <section className="background-section left-image">
        <div className="Productcontent">
          <div className="Productleft">
            <img
              src="/images/los.png"
              alt="Cync LOS"
              className="Productimage"
            />
          </div>
          <div className="Productright">
            <h1 className="tophed">Cync LOS</h1>
            <h2>
              Highly Flexible and Scalable End-to-End Commercial Loan
              Origination System to Fast-Track Lending
            </h2>
            <p className="toppara">
              Design custom loan origination workflows for any risk appetite
              with unmatched agility. Propel innovation with an API-first
              approach and scale on demand with an intelligent, cloud-based
              solution—all this, without writing a line of code.
            </p>
            <a
              href="/cync-loan-origination-system-los"
              onClick={() =>
                handleNavigation("/cync-loan-origination-system-los")
              }
            >
              <Button className="mobilebtn" size="small" color="primary">
                Read More
              </Button>
            </a>
          </div>
        </div>
      </section>

      <section className="background-section right-image">
        <div className="Productcontent">
          <div className="Productleft">
            <h1 className="tophed">Cync ABL</h1>
            <h2>
              Industry-Leading Asset-Based Lending Platform To Completely
              Automate Collateral Evaluation and Borrowing Base Calculations
            </h2>
            <p className="toppara">
              Evaluate any type of collateral on-demand, including marketable
              securities. Generate Borrowing Base Certificates in seconds, with
              a wide range of calculations and the flexibility to mirror your
              loan agreements. Improve risk management with dynamic reports and
              detailed trend cards, all while enhancing the borrower experience.
            </p>
            <a href="/cync-abl" onClick={() => handleNavigation("/cync-abl")}>
              <Button className="mobileablbtn" size="small" color="primary">
                Read More
              </Button>
            </a>
          </div>
          <div className="Productright">
            <img
              src="/images/abl.png"
              alt="Cync ABL"
              className="Productimage"
            />
          </div>
        </div>
      </section>

      <section className="background-section left-image">
        <div className="Productcontent">
          <div className="Productleft">
            <img
              src="/images/syndicated.png"
              alt="Spreading"
              className="Productimage"
            />
          </div>
          <div className="Productright">
            <h1 className="tophed">Cync Syndicated Lending</h1>
            <h2>
              Highly Flexible, Automated Solution for Simplifying Syndicated
              Loan Management
            </h2>
            <p className="toppara">
              Automate the servicing of both syndicated and participation loans.
              Seamlessly handlecomplexities like dividing loans into multiple
              tranches, prioritizing lender allocations, and triggering
              drawdowns as needed. Allow syndicate participants and borrowers
              exceptional flexibility to structure contracts for their unique
              financing and risk management requirements.
            </p>
            <a
              href="/cync-syndicated-lending"
              onClick={() => handleNavigation("/cync-syndicated-lending")}
            >
              <Button className="mobilebtn" size="small" color="primary">
                Read More
              </Button>
            </a>
          </div>
        </div>
      </section>

      <section className="background-section right-image">
        <div className="Productcontent">
          <div className="Productleft">
            <h1 className="tophed">Cync Spreading</h1>
            <h2>
              AI-Powered Standalone Solution to Revolutionize Financial
              Spreading Through Automation
            </h2>
            <p className="toppara">
              Design custom spreading templates tailored to specific clients or
              industries, or take advantage of the built-in master templates.
              Pivot to a highly automated spreading process and reclaim hours of
              back-office productivity.
            </p>
            <a
              href="/cync-spreading"
              onClick={() => handleNavigation("/cync-spreading")}
            >
              <Button size="small" color="primary" className="mobilefacbtn">
                Read More
              </Button>
            </a>
          </div>
          <div className="Productright">
            <img
              src="/images/spreading.png"
              alt="Cync spreading"
              className="Productimage"
            />
          </div>
        </div>
      </section>

      <section className="background-section left-image">
        <div className="Productcontent">
          <div className="Productleft">
            <img
              src="/images/factoring.png"
              alt="factoring"
              className="Productimage"
            />
          </div>
          <div className="Productright">
            <h1 className="tophed">Cync Factoring</h1>
            <h2>
              Cloud-Based Factoring Platform to Automate Invoice Validation and
              Fast-Track Funding to Sellers
            </h2>
            <p className="toppara">
              Streamline your end-to-end invoice validation and verification
              process with automation. Switch to a highly scalable and resilient
              cloud-based platform and elevate both your back-office operations
              and seller experience.
            </p>
            <a
              href="/cync-factoring"
              onClick={() => handleNavigation("/cync-factoring")}
            >
              <Button className="mobilebtn" size="small" color="primary">
                Read More
              </Button>
            </a>
          </div>
        </div>
      </section>

      <div className="space"></div>

      <div className="clientlogossec">
        <h1 style={heading}>Our Clients</h1>
        <HrLines />
        <section className="Clientlogosection">
          <div className="logo-grid">
            <img
              src="/logos/americanbank.png"
              alt="First American Bank"
              className="Clientlogo"
            />
            <img
              src="/logos/techcapital.png"
              alt="Tech Capital"
              className="Clientlogo"
            />
            <img
              src="/logos/countrybank.png"
              alt="Country Bank"
              className="Clientlogo"
            />
            <img
              src="/logos/parabills.png"
              alt="Country Bank"
              className="Clientlogo"
            />
          </div>
        </section>
      </div>

      <div className="space"></div>

      {/**Tech Section */}

      <section>
        <Container>
          <h1 style={heading}>
            Robust Technology Platform for
            <br />
            Lending Automation{" "}
          </h1>
          <HrLines />
          <div className="space"></div>
          <Row className="box-row">
            {Lendingdata.map((box) => (
              <Col key={box.id} md={3} sm={6} xs={12} className="box-col">
                <Card
                  className="box"
                  id={`box-${box.id}`}
                  onClick={() => flipBox(box.id)}
                >
                  <div className="front">
                    <div className="icon-top-right">
                      <img src={reverse} alt="Extra Icon" />
                    </div>
                    <img src={box.icon} alt={box.title} />
                    <br />
                    <h3>{box.title}</h3>
                  </div>
                  <div className="back">
                    <p>{box.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      {/**lending Section 
      <section className="backgroundlending-section">
        <div className="Lendingcontent">
          <div className="Lendingleft">
            <h1>Commercial Lending Solutions</h1>
            <p>
              Cync Commercial Lending Solutions delivers a high fit for
              commercial loan <br />
              operations, boosts efficiency and reduce risk. Fully automated,
              low-code-based commercial loan origination backed by the
              best-in-class AI.
            </p>
            <Button size="small" color="primary">
              Read More
            </Button>
          </div>
          <div className="Lendingright">
            <img
              src="/images/lending.png"
              alt="Cync LOS"
              className="Lendingimage"
            />
          </div>
        </div>
      </section>

      <div className="space"></div>*/}

      {/** Sponsor Section */}
      <div className="bglogo">
        <h1 style={heading}>
          Seamless Integrations for <br />
          Custom Solutions That Meet Your Needs
        </h1>
        <HrLines />
        <section className="besection">
          <div className="marquee-container">
            <div className="marquee">
              <div className="logo-grids">
                {repeatedLogos.map((logo, index) => (
                  <div
                    key={index}
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <a
                      href={logo.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={logo.src}
                        alt={`logo-${index}`}
                        className="spnlogo"
                        data-tooltip-id={`tooltip-${index}`}
                      />
                    </a>
                    <Tooltip
                      id={`tooltip-${index}`}
                      place="top"
                      effect="solid"
                      className="custom-tooltip"
                    >
                      {logo.tooltip}
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="space"></div>

      <section className="testimoni">
        <Carousel
          indicators={true}
          controls={false}
          interval={carouselPlaying ? 15000 : null}
          fade={true} // Add fade effect for smooth transition
          className="carousel-container"
        >
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index} className="carousel-item">
              <img
                className="d-block w-100 carousel-image"
                src={testimonial.image}
                alt={`Slide ${index + 1}`}
              />
              <Carousel.Caption className="carousel-caption-custom">
                <h1 className="headingwhite">{testimonial.heading}</h1>
                <p className="testimobpara">{testimonial.text}</p>
                <p className="author-text">{testimonial.author}</p>
                <p className="none"></p>
                <p
                  style={{ fontSize: "11.5px" }}
                  className="testimobpara dispara"
                >
                  <i>{testimonial.disclaimer}</i>
                </p>
                <div
                  className="disclaimer"
                  style={{ gap: "10px", display: "flex" }}
                >
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => handleOpen(testimonial.videoUrl)}
                  >
                    Watch Video
                  </Button>
                  {(index === 0 || index === 1 || index === 2 ) && (
                    <a href={testimonial.pageUrl}>
                      <Button size="sm" variant="light">
                        Read More
                      </Button>
                    </a>
                  )}
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <VideoModal open={open} handleClose={handleClose} videoUrl={videoUrl} />
      </section>

      <div className="space"></div>

      {/**blog Section */}
      <h1 style={heading}>Our Insights</h1>
      <HrLines />

      <div className="space"></div>

      <Container>
        <Row xs={1} md={3} className="g-4">
          {recentPosts.map((insight) => (
            <Col key={insight.id}>
              <Card className="h-100">
                <p className="date">{insight.date}</p>
                <Link
                  to={insight.buttonLink}
                  onClick={() => handleNavigation(insight.buttonLink)}
                >
                  <Card.Img
                    variant="top"
                    src={`/images/${insight.imageName}`}
                    alt={`Insight ${insight.id}`}
                  />
                </Link>
                <Card.Body className="insightsbody">
                  <Card.Text className="insightstext">
                    {insight.boxName}
                  </Card.Text>
                  <Link
                    to={insight.buttonLink}
                    onClick={() => handleNavigation(insight.buttonLink)}
                  >
                    <Card.Title>{insight.title}</Card.Title>
                  </Link>
                </Card.Body>
                <Link
                  to={insight.buttonLink}
                  onClick={() => handleNavigation(insight.buttonLink)}
                  className="read-more"
                >
                  {insight.buttonLabel}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ margin: "5px", float: "right" }}
                  />
                </Link>
              </Card>
            </Col>
          ))}
          <Col key={thirdBoxContent.id}>
            <Card className="h-100">
              <p className="date">{thirdBoxContent.date}</p>
              <Link
                to={thirdBoxContent.buttonLink}
                onClick={() => handleNavigation(thirdBoxContent.buttonLink)}
                
              >
              <Card.Img
                variant="top"
                src={`/images/${thirdBoxContent.imageName}`}
                alt="Special"
              /></Link>
              <Card.Body className="insightsbody">
                <Card.Text className="insightstext">
                  {thirdBoxContent.content}
                </Card.Text>
                <Link
                to={thirdBoxContent.buttonLink}
                onClick={() => handleNavigation(thirdBoxContent.buttonLink)}
                
              >
                <Card.Title>{thirdBoxContent.title}</Card.Title></Link>
              </Card.Body>
              <Link
                to={thirdBoxContent.buttonLink}
                onClick={() => handleNavigation(thirdBoxContent.buttonLink)}
                className="read-more"
              >
                {thirdBoxContent.buttonLabel}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ margin: "5px", float: "right" }}
                />
              </Link>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="space"></div>
      {/* <AlertPopup /> */}
      <Newsletter />
      <ScheduleDemo />
    </div>
  );
};

export default MainSection;
