import React from "react";
import "../../assets/css/home.css";
import "../../assets/css/CyncTeam.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import HrLines from "../designs/HrLines";
import { useState } from "react";
import { Helmet } from "react-helmet";

const CyncTeam = () => {
  const [flippedId, setFlippedId] = useState(null);

  const teamMembers = [
    {
      id: 1,
      name: "Lydia Taylor",
      jobTitle: "Director of Sales",
      imageUrl: "/team/Lydia",
      description:
        "Lydia graduated from Hiram College with a BA in Business Management & spent the last 10 years in the banking/financial services & FinTech industries. Lydia has worked throughout her career to improve her client's experiences & build confidence with consumers through asset protection & growth solutions. These efforts made her a corporate officer with previous employers & the recipient of the Star Power Award. She is a member of her local CFA, the national American Bankers Association, & the Leukemia & Lymphoma Society’s 2018 Woman of the Year. Lydia’s hobbies include photography, CrossFit, singing, and playing the keyboard.",
    },
    {
      id: 2,
      name: "Jennifer  Ballard",
      jobTitle: "Director of Client Services",
      imageUrl: "/team/Jennifer",
      description:
        "Jennifer started her career as a Controller for NDS Systems & now serves as the Director of Client Services. She has worked with NDS Systems for over 20 years & attributes her success to 'never giving up'. (Disclaimer: don’t be fooled by her tiny stature)! Jennifer attended the University of South Florida, along with St. Petersburg College, where she studied Accounting. In her free time, she teaches QuickBooks to startups & small companies in need of training. Jennifer’s true passion is spending time with her two Great Danes, serving at her church, & being with her family.",
    },
    {
      id: 3,
      name: "Nick Chupp",
      jobTitle: "Development Solutions Architect",
      imageUrl: "/team/Nick",
      description:
        "Nick earned his bachelor’s degree in Kinesiology & Exercise Science from Florida State University. Though his education was in the medical field, he discovered a passion for software management, leading Nick to wear many hats in the software industry, from technical support to product development. At Cync, Nick works with the development team to maintain high standards for customer service & product quality, driving development strategies & procedures by translating client suggestions & issues. In his free time, Nick enjoys going on adventures with his dog, golfing, & playing beach volleyball.",
    },
    {
      id: 4,
      name: "Jessica  Bexley",
      jobTitle: "Senior Implementation & Support Analyst",
      imageUrl: "/team/Jessica",
      description:
        "Jessica graduated with a BS in Business Administration/Marketing from the University of Florida & joined Cync after almost a decade in the factoring industry. Throughout her career in factoring, Jessica's roles spanned multiple departments & management levels. She had a hand in everything from portfolio management to business development. Jessica's experience provides leverage on both the operational & client-facing sides of implementation. In her downtime, Jessica enjoys spending time with her kids, husband, & their rowdy chocolate lab.",
    },
    {
      id: 5,
      name: "Sean Catalanotto",
      jobTitle: "Manager of Support",
      imageUrl: "/team/Sean",
      description:
        "Sean earned his bachelor's degree in English Literature from DeSales University. With a career & background in both education & technology, Sean has acquired valuable experience in process creation, product management, software testing, & development. As a skilled trainer with a great understanding of customer's needs, he strives to provide exemplary technical support. In his free time, Sean plays soccer in the Tampa Area leagues & enjoys volunteering & running 1/2 marathons with his wife. He also loves all things related to PC gaming, music technology, & hardware.",
    },
    {
      id: 6,
      name: "Tanner James",
      jobTitle: "Data Integration Analyst",
      imageUrl: "/team/Tanner",
      description:
        "Tanner graduated with a B.S. in Business Administration/Finance from the University of South Carolina. He joined Cync after 5+ years in the Asset-Based Lending industry, where he acquired valuable experience as a Field Examiner & Collateral Analyst. With a professional background in both the public & private sectors, he has shaped an excellent reputation for resolving problems & improving customer satisfaction. In his free time, Tanner has a passion for personal finance/investing & enjoys traveling, running, & gaming.",
    },
    {
      id: 7,
      name: "Fatima Golden",
      jobTitle: "Implementation Manager & Support Analyst",
      imageUrl: "/team/Fatima",
      description:
        "Fatima joins Cync with over 8 years of experience in the banking industry. Throughout her career, Fatima has worked in many areas of banking, including Asset-Based Lending & Commercial Real Estate. She attended Norfolk State University, where she studied Political Science before finding her passion. In her free time, she enjoys spending time with family, traveling, & supporting the Washingtonian Ballet as a member of the Jete Society.",
    },
    {
      id: 8,
      name: "Cynthia Miller",
      jobTitle: "Implementation Consultant",
      imageUrl: "/team/Cynthia",
      description:
        "Cynthia joined Cync after spending almost 20 years in community bank lending. She began her career as a credit analyst at a small bank and has since worked in all facets of lending, including loan administration, lending operations, lending compliance, portfolio management and underwriting. Most recently, Cynthia worked as a VP of Commercial Loan Administration, managing special projects and commercial operations. Cynthia earned her MBA from Bryant University in Rhode Island. In her downtime, Cynthia enjoys wrangling her four kids and energetic Pitsky, creating delectable gluten free confections, and spending time on the golf course and beach.",
    },
    {
      id: 9,
      name: "Kendra Radner",
      jobTitle: "Senior Management Consultant",
      imageUrl: "/team/Kendra",
      description:
        "Kendra joins Cync with nearly 15 years of experience in community banking where she held various positions including VP, Internal Audit and VP, Commercial Credit. She also spent six years working in the energy industry where she helped design a collateral margining system. Over her career, she has worn many hats, including business analyst, systems analyst, project lead, process analyst, and trainer. Kendra earned an MBA from Western New England University and B.S. in Finance from Florida State University, Kendra enjoys traveling, spending time with family, and spoiling her Maine coon cats.",
    },
    {
      id: 10,
      name: "Gena VanMeter",
      jobTitle: "Account Executive",
      imageUrl: "/team/Gena",
      description:
        "Gena is a native of Texas and graduated from North Texas University. Gena joins Cync after 5+ years as a Senior Sales Development Representative in the Financial Industry. She has acquired valuable experience with loan origination, helping financial institutions foster a seamless lending experience while building lasting relationships. She has a passion for music, movies, ministry, and helping others develop their talent and purpose.",
    },
  ];
  const handleFlip = (id) => {
    setFlippedId(flippedId === id ? null : id);
  };

  return (
    <div>
      <Helmet>
        <title>Leadership Team - Cync Software®</title>
        <meta
          name="description"
          content="Get to know our dedicated team driving innovation and excellence in lending solutions and technology."
        />
      </Helmet>

      <section className="company-section">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h1>Our Team</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <Container>
        <Row>
          <Col lg={12}>
            <h1 className="mb-4" style={{ textAlign: "center" }}>
              Meet The Experts
            </h1>
            <HrLines />
            <p style={{ marginTop: "20px", fontSize: "20px" }}>
              Are you a lender looking for a true partner that works with
              clients to understand needs and build on the latest trends for
              unparalleled growth and efficiency?  Cync Software’s team of
              financial and technical experts delivers innovative solutions and
              customer service to streamline processes for lenders. Contact
              Us today and see what Cync Software can do for you.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="team-section">
        <Row xs={1} md={4} className="g-4">
          {teamMembers.map((member) => (
            <Col key={member.id} className="teamcol">
              <Card
                className={`team-member ${
                  flippedId === member.id ? "flipped" : ""
                }`}
                onClick={() => handleFlip(member.id)}
              >
                <div className="member-image">
                  {flippedId !== member.id && (
                    <span
                      className="plus-sign"
                      onClick={() => handleFlip(member.id)}
                    >
                      +
                    </span>
                  )}
                  {flippedId !== member.id && (
                    <picture>
                    <source srcSet={`${member.imageUrl}.avif`} type="image/avif" />
                    <source srcSet={`${member.imageUrl}.webp`} type="image/webp" />
                    <source srcSet={`${member.imageUrl}.png`} type="image/png" />
                    <img src={`${member.imageUrl}.jpg`} alt={member.name} />
                  </picture>
                  )}
                </div>
                <Card.Body
                  className={flippedId === member.id ? "back-description" : ""}
                >
                  <Card.Title>
                    {flippedId !== member.id ? member.name : null}
                  </Card.Title>
                  <Card.Text style={{fontSize:'15px'}}>
                    {flippedId !== member.id ? member.jobTitle : null}
                  </Card.Text>
                  {flippedId === member.id && (
                    <div className="member-description" style={{fontSize:'13.5px'}}>
                      {member.description}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="space"></div>
      <div className="space"></div>
    </div>
  );
};

export default CyncTeam;
